import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

const ProductionByPadChart = ({ productionData }) => {
  const [groupedData, setGroupedData] = useState({});
  const [chartData, setChartData] = useState([]);
  const [padOptions, setPadOptions] = useState([]);

  useEffect(() => {
    if (!productionData || !Array.isArray(productionData.data)) {

      return;
    }

    const newGroupedData = {};
    const padSet = new Set();

    productionData.data.forEach((entry) => {
      const {
        metadata: { pad_id },
        values,
      } = entry;
      padSet.add(pad_id);

      values.forEach((value) => {
        const producedOil = value[7];
        const producedWater = value[8];
        const producedGas = value[6];

        if (!newGroupedData[pad_id]) {
          newGroupedData[pad_id] = {
            produced_oil: 0,
            produced_water: 0,
            produced_gas: 0,
          };
        }

        newGroupedData[pad_id].produced_oil += producedOil;
        newGroupedData[pad_id].produced_water += producedWater;
        newGroupedData[pad_id].produced_gas += producedGas;
      });
    });

    setGroupedData(newGroupedData);
    setPadOptions(Array.from(padSet));
  }, [productionData]);

  useEffect(() => {
    if (Object.keys(groupedData).length > 0) {
      updateChartData(groupedData);
    }
  }, [groupedData]);

  const updateChartData = (data) => {
    const plotData = [
      {
        x: Object.keys(data),
        y: Object.keys(data).map((padKey) => data[padKey].produced_oil),
        type: "bar",
        name: "Oil",
        marker: { color: "#FF5733" }, // Distinct color for oil
        yaxis: "y1",
      },
      {
        x: Object.keys(data),
        y: Object.keys(data).map((padKey) => data[padKey].produced_water),
        type: "bar",
        name: "Water",
        marker: { color: "#3357FF" }, // Distinct color for water
        yaxis: "y1",
      },
      {
        x: Object.keys(data),
        y: Object.keys(data).map((padKey) => data[padKey].produced_gas),
        type: "scatter",
        mode: "lines+markers",
        name: "Gas",
        marker: { color: "#33FF57" }, // Distinct color for gas
        yaxis: "y2",
      },
    ];

    setChartData(plotData);
  };

  const layout = {
    title: "Production by Pad",
    xaxis: {
      title: "Pad ID",
      tickfont: {
        color: "white",
      },
      gridcolor: "rgba(128, 128, 128, 0.3)",
      range: [-0.5, padOptions.length - 0.5], // Adjusted to remove extra space
    },
    yaxis: {
      title: "Volume (bbl)",
      tickfont: {
        color: "white",
      },
      gridcolor: "rgba(128, 128, 128, 0.3)",
    },
    yaxis2: {
      title: "Volume (mcf)",
      overlaying: "y",
      side: "right",
      tickfont: {
        color: "white",
      },
      gridcolor: "rgba(128, 128, 128, 0.3)",
    },
    barmode: "group",
    margin: { l: 60, r: 80, t: 50, b: 70 },
    legend: { orientation: "h", x: 0, xanchor: "left", y: 1.1 },
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    font: {
      color: "#ffffff", // Set text color to white
    },
    displayModeBar: true, // Always show the modebar
    modebar: {
      orientation: "v",
      x: 0.95, // Moved inside the plot area
      xanchor: "left",
      y: 1,
      yanchor: "top",
      bgcolor: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <div className="rounded-box text-white mb-6">
      {chartData.length > 0 ? (
        <Plot
          data={chartData}
          layout={layout}
          style={{ width: "100%", height: "450px" }}
          useResizeHandler={true}
          config={{
            scrollZoom: true,
            displaylogo: false,
            responsive: true,
            displayModeBar: true, // Always show the modebar
          }}
        />
      ) : (
        <div className="flex items-center justify-center h-64">
          <p>No data loaded yet.</p>
        </div>
      )}
    </div>
  );
};

export default ProductionByPadChart;
