import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

export const OperatorsSchedule = ({ frame }) => {
  console.log("frame", frame);
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    if (frame && frame.sick_days) {
      const parsedEvents = frame.sick_days.map((sickDay) => {
        const [operator, startDay, endDay] = sickDay;
        const start = moment().year(currentYear).dayOfYear(startDay).toDate();
        const end = moment().year(currentYear).dayOfYear(endDay).toDate();
        return {
          title: `${operator} (Sick)`,
          start: start,
          end: end,
          allDay: true,
          resource: { operator },
        };
      });
      setEvents(parsedEvents);
    } else {
      setEvents([]);
    }
  }, [frame]);

  const filteredEvents = events.filter(event =>
    event.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex w-full bg-base-200 shadow-lg rounded-lg p-4">
      <div className="w-1/2 pr-4">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          titleAccessor="title"
          style={{ height: 500 }}
          views={['month', 'week', 'day']}
          defaultView="month"
          className="text-base-content"
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: "#f87171",
              color: "white",
            },
          })}
          toolbar={true}
          components={{
            toolbar: (props) => (
              <div className="flex justify-between items-center">
                <div>
                  <button onClick={() => props.onNavigate('PREV')} className="btn btn-sm mr-2">
                    &lt;
                  </button>
                  <button onClick={() => props.onNavigate('NEXT')} className="btn btn-sm">
                    &gt;
                  </button>
                </div>
                <div className="text-lg font-bold">{props.label}</div>
                <button onClick={() => props.onNavigate('TODAY')} className="btn btn-sm">
                  Today
                </button>
                <div>
                  <button onClick={() => props.onView('month')} className="btn btn-sm mr-2">
                    Month
                  </button>
                  <button onClick={() => props.onView('week')} className="btn btn-sm mr-2">
                    Week
                  </button>
                  <button onClick={() => props.onView('day')} className="btn btn-sm">
                    Day
                  </button>
                </div>
              </div>
            ),
          }}
        />
      </div>
      <div className="w-1/2 pl-4">
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search by event/name..."
            className="input input-bordered w-full"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="overflow-y-auto h-96">
          {filteredEvents.map((event, index) => (
            <div key={index} className="bg-primary text-primary-content p-2 mb-2 rounded-lg">
              <div className="flex justify-between items-center">
                <div>
                  <span className="badge badge-secondary mr-2">
                    {moment(event.start).format("MMM DD")}
                  </span>
                  <span>{event.title}</span>
                </div>
                <span className="badge badge-accent">
                  {moment(event.start).format("HH:mm")} - {moment(event.end).format("HH:mm")}
                </span>
              </div>
            </div>
          ))}
          {filteredEvents.length === 0 && (
            <div className="text-center text-gray-500 mt-4">
              No events found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

 
