import React, { useState, useMemo } from "react";
import Select from "react-select";

const Notifications = ({ notifications = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [filterCant, setFilterCant] = useState(true);

  const rowsPerPageOptions = [
    { value: 3, label: "3 rows" },
    { value: 10, label: "10 rows" },
    { value: 20, label: "20 rows" },
  ];

  const filteredNotifications = useMemo(() => {
    const uniqueNotifications = Array.from(
      new Set(notifications.map((n) => n.message))
    ).map((message) => notifications.find((n) => n.message === message));

    return filterCant
      ? uniqueNotifications.filter(
          (notification) => !notification.message.includes("can't")
        )
      : uniqueNotifications;
  }, [filterCant, notifications]);

  const totalPages = Math.ceil(filteredNotifications.length / rowsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (selectedOption) => {
    setRowsPerPage(selectedOption.value);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  const handleFilterCantChange = () => {
    setFilterCant(!filterCant);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const currentNotifications = useMemo(() => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = currentPage * rowsPerPage;
    return filteredNotifications.slice(start, end);
  }, [currentPage, rowsPerPage, filteredNotifications]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "var(--daisyui-bg-base-200)",
      color: "var(--daisyui-text-base-content)",
      borderColor: "var(--daisyui-border-base-200)",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "var(--daisyui-bg-base-200)",
      color: "var(--daisyui-text-base-content)",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--daisyui-text-base-content)",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "var(--daisyui-text-base-content)",
    }),
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="form-control">
          <label className="cursor-pointer label">
            <span className="label-text mr-2">Hide warning messages</span>
            <input
              type="checkbox"
              className="toggle toggle-primary"
              checked={filterCant}
              onChange={handleFilterCantChange}
            />
          </label>
        </div>
        <Select
          options={rowsPerPageOptions}
          defaultValue={rowsPerPageOptions[0]}
          onChange={handleRowsPerPageChange}
          className="w-1/4"
          styles={customStyles}
        />
      </div>
      <div className="overflow-x-auto">
        <table className="table w-full border-collapse">
          <thead>
            <tr className="bg-base-200 text-base-content">
              <th className="p-2">Operator ID</th>
              <th className="p-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {currentNotifications.map((notification, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? 'bg-base-200' : 'bg-base-100'
                } hover:bg-base-300`}
              >
                <td className="text-white p-2">{notification.operator_id}</td>
                <td className="p-2">
                  <div
                    role="alert"
                    className={`alert ${
                      notification.message.includes("can't")
                        ? "alert-warning"
                        : "alert-success"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current shrink-0 h-6 w-6 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      {notification.message.includes("can't") ? (
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      ) : (
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      )}
                    </svg>
                    <span>{notification.message}</span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between mt-4">
        <button
          className="btn btn-primary"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="text-sm text-gray-500">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="btn btn-primary"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Notifications;
