const itemPerPage = [{
  value: 10,
  label: 10,
},
{
  value: 20,
  label: 20,
},
{
  value: 50,
  label: 50,
},
{
  value: 100,
  label: 100,
}];
const ItemPerPage = ({dataSet, setPageby, setPagenation, setCurrentPage, setTotalPages}) => {
  const handleChange = (e) => {
    setPageby(parseInt(e.target.value))
    setPagenation({ start: 0, end: parseInt(e.target.value) })  
    setCurrentPage(1)
    setTotalPages(Math.ceil(dataSet.length / parseInt(e.target.value)))
  }
  return (
    <div className="flex">
      <select onChange={handleChange}>
        {
          itemPerPage.map((item) => {
            return <option value={item.value} key={item.value}>{item.label}</option>
          })
        }
      </select>
    </div>
  )
}

export default ItemPerPage