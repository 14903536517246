import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

const VolumeFlaredByPad = ({ productionData }) => {
  const [chartData, setChartData] = useState({ data: [], layout: {} });

  useEffect(() => {
    if (!productionData || !Array.isArray(productionData.data)) {
      return; // Early return if data is not correctly formatted
    }

    const padVolumeFlared = {};

    productionData.data.forEach((entry) => {
      const {
        metadata: { pad_id },
        values,
      } = entry;

      values.forEach((value) => {
        const volumeFlared = value[2]; // Assuming index 2 is volumeFlared based on your schema

        if (!padVolumeFlared[pad_id]) {
          padVolumeFlared[pad_id] = 0;
        }

        padVolumeFlared[pad_id] += volumeFlared;
      });
    });

    const padIds = Object.keys(padVolumeFlared);
    const flaredVolumes = padIds.map((padId) => padVolumeFlared[padId]);

    updateChartData(padIds, flaredVolumes);
  }, [productionData]);

  const updateChartData = (padIds, flaredVolumes) => {
    const series = [
      {
        x: padIds,
        y: flaredVolumes,
        type: "bar",
        name: "Volume Flared",
        marker: { color: "rgba(54, 162, 235, 0.8)" },
      },
    ];

    const layout = {
      title: {
        text: "Volume Flared by Pad",
        font: {
          family: "Arial, sans-serif",
          size: 18,
          color: "#ffffff",
        },
      },
      xaxis: {
        title: "Pad ID",
        tickfont: {
          color: "white",
        },
        gridcolor: "rgba(128, 128, 128, 0.3)",
        showgrid: false, // Remove grid lines
        zeroline: false, // Remove zero line
        showline: true, // Add axis line
        linecolor: "rgba(255, 255, 255, 0.2)", // Light axis line color
      },
      yaxis: {
        title: "Volume Flared (mcf)",
        tickfont: {
          color: "white",
        },
        gridcolor: "rgba(128, 128, 128, 0.3)",
        nticks: 10,
        showgrid: true, // Add grid lines
        zeroline: false, // Remove zero line
        showline: true, // Add axis line
        linecolor: "rgba(255, 255, 255, 0.2)", // Light axis line color
        automargin: true, // Auto-scale the y-axis
      },
      showlegend: true,
      legend: {
        orientation: "h",
        x: 0,
        xanchor: "left",
        y: 1.1,
        font: {
          color: "#ffffff",
        },
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      font: {
        color: "#ffffff",
      },
      autosize: true,
      margin: { l: 60, r: 20, t: 50, b: 70 }, // Reduced right margin to minimize space between chart and controls
      modebar: {
        orientation: "v",
        x: 1,
        xanchor: "right",
        y: 1,
        yanchor: "top",
        bgcolor: "rgba(0, 0, 0, 0.3)",
      },
    };

    setChartData({ data: series, layout });
  };

  return (
    <div className="rounded-sm p-2 shadow-sm relative">
      {chartData.data.length > 0 && (
        <Plot
          data={chartData.data}
          layout={chartData.layout}
          style={{ width: "100%", height: "450px" }}
          useResizeHandler={true}
          config={{
            scrollZoom: true,
            displaylogo: false,
            responsive: true,
            displayModeBar: true, // Always show the modebar
          }}
        />
      )}
    </div>
  );
};

export default VolumeFlaredByPad;
