import React, { useState } from "react";
import FormAlert from "./FormAlert";
import AuthForm from "./AuthForm";
import AuthSocial from "./AuthSocial";
import { useRouter } from "./../util/router";

function Auth(props) {
  const router = useRouter();
  const [formAlert, setFormAlert] = useState(null);
  console.log(" Auth.js:", props);

  const handleAuth = (user) => {
    router.push(props.afterAuthPath);
  };

  const handleFormAlert = (data) => {
    setFormAlert(data);
  };

  return (
    <>
      <div className="bg-white p-8 rounded shadow-lg w-full max-w-md  lg:my-16 md:my-8 sm:my-4">
        {formAlert && (
          <div className="mb-4">
            <FormAlert type={formAlert.type} message={formAlert.message} />
          </div>
        )}
        <div className="flex justify-center mb-6">
          <img
            src="https://www.mavresources.com/wp-content/themes/maverick/img/logo.png"
            alt="Maverick Logo"
            className="h-12"
          />
        </div>
        <h2 className="text-2xl font-bold mb-6 text-center">
          Login to Maverick Hackathon
        </h2>

        <AuthForm
          type={props.type}
          buttonAction={props.buttonAction}
          onAuth={handleAuth}
          onFormAlert={handleFormAlert}
        />
      </div>
    </>
  );
}

export default Auth;
