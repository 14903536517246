import { useState, useEffect } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";
import Pagenation from '../../util/pagenation';
import ItemPerPage from '../../util/itemPerPage';
import getMessage from '../../util/getMessage';
const NotificationByPad = ({ notifications,
  isOverview,
  isPad,
  header }) => {
  
    const [dataSet, setDataSet] = useState([]);
    const [originalDataSet, setOriginalDataSet] = useState([]);
    const [pagenation, setPagenation] = useState({ start: 0, end: 10 });
    const [pageby, setPageby] = useState(10);
    const [subPageNation, setSubPagenation] = useState({});
    const [subPageby, setSubPageby] = useState({});
    const [subCurrentPage, setSubCurrentPage] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [subTotalPages, setSubTotalPages] = useState({});
    const [toggleNotification, setToggleNotification] = useState({})
    
    useEffect(() => {
      if (notifications) {
        const mappedNotifications = getMappedNotifications(notifications)
        const sanitizedNotifications = getSanitizedNotifications(mappedNotifications)
        setOriginalDataSet(sanitizedNotifications)
        setDataSet(sanitizedNotifications)
        setupSubPagenation(sanitizedNotifications)
      }
    }, [notifications])
  
    useEffect(() => {
      if (dataSet) {
        setTotalPages(Math.ceil(dataSet.length / pageby))
        setPagenation({ start: 0, end: pageby })
      }
    }, [dataSet, pageby, notifications])
   
  const getSanitizedNotifications = (notifications) => {
    const flattenedNotifications = notifications.flat(Infinity);
    
    const sanitizedNotifications = flattenedNotifications.reduce((acc, notification) => {
      if (!acc[notification.pad_id] && notification.pad_id !== undefined) {
        acc[notification.pad_id] = [];
      }
      else if (acc[notification.pad_id]) {
        acc[notification.pad_id].push(notification);
      }
      else if(acc[notification.asset_id]) {
        acc[notification.asset_id].push(notification);
      }
      return acc;
    }, {});

    const mappedData = Object.entries(sanitizedNotifications).map(([key, value]) => ({ pad_id: key, notifications: value }));
    return mappedData.sort((a, b) => {
      return parseInt(b.pad_id.substring(3)) - parseInt(a.pad_id.substring(3))
    })
  }
  
  const getMappedNotifications = (notifications) => {
    
    return notifications.map((ns, index) => {
      return ns.map((notification) => {
        return {
          frameNo: `Frame-${index}`,
          ...notification
          }
        })
      })
    }
  
    const setupSubPagenation = (data) => {
      if (data) {
        let subpn = {}
        let subpb = {}
        let subcp = {}
        let subtp = {}
         
        data.forEach(({notifications, pad_id}, index) => {
          subpn = { ...subpn, [pad_id]: { start: 0, end: 10 } }
          subpb = { ...subpb, [pad_id]: 10 }
          subcp = { ...subcp, [pad_id]: 1 }
          subtp = { ...subtp, [pad_id]: Math.ceil(notifications.length / 10) }
      })
        setSubPagenation(subpn)
        setSubPageby(subpb)
        setSubCurrentPage(subcp)
        setSubTotalPages(subtp)
      }
    }
  
  const handleSubPagenation = (page, pad_id) => {
    setSubPagenation({ ...subPageNation, [pad_id]: page });
  }

  const handleSubCurrentPage = (page, pad_id) => {
    setSubCurrentPage({ ...subCurrentPage, [pad_id]: page });
  }
  const renderNotifications = (notifications) => {
    return (
      <div>
        <table className="table w-full text-center">
          <thead>
            <tr
              className="border-b border-primary"
            >
            <th>Frame</th>
            <th>Operator</th>
            <th>Pipeline</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
        {
          notifications.map(({ frameNo, pipeline, asset_id, message, operator_id, pad_id }, index) => {
            
            return (
              <tr key={index} className="text-white border-b border-primary">
                <td>{ frameNo }</td>
                <td>{operator_id}</td>
                <td>{ pipeline }</td>
                <td>{getMessage(message) }</td>
                
              </tr>
            )
          })
            }
            </tbody>
        </table>
      </div>
     )
    }
  
  const renderNotificationTable = () => {

      const pagenatedDataSet = dataSet.slice(pagenation.start, pagenation.end);
      
      return pagenatedDataSet.map(( {pad_id, notifications} ) => {
        
        const pagenatedPadData = notifications.slice(subPageNation[pad_id].start, subPageNation[pad_id].end)

        
        return (
          <div key={pad_id}>
            <div        
              className="border-b border-primary p-2 flex justify-between items-center cursor-pointer"
            >
              <div
                className="text-primary text-xl pb-2 font-bold w-full flex flex-space-between items-center"
                  onClick={() => setToggleNotification({ ...toggleNotification, [pad_id]: !toggleNotification[pad_id] })}>
                  <div className='w-full'>{pad_id}</div>
                  <div>
                    {toggleNotification[pad_id] ? <ChevronDownIcon className="w-5 h-5 text-primary" /> : <ChevronUpIcon className="w-5 h-5 text-primary" />}
                  </div>
              </div>
            </div>
            
            {toggleNotification[pad_id] && (
              <div className="pl-2 pr-2">
                  {renderNotifications(pagenatedPadData)}  
                  <Pagenation
                  pagenation={subPageNation[pad_id]}
                  setPagenation={ (data) => handleSubPagenation(data, pad_id) }
                  setCurrentPage={ (data) => handleSubCurrentPage(data, pad_id) }
                  pageby={ subPageby[pad_id] }
                  currentPage={ subCurrentPage[pad_id]}
                  totalPages={ subTotalPages[pad_id] }
                  length={ notifications.length}
                  />
                  
              </div>
            )}
            

           
          </div>
        )
      })
    }
    
    return (
      <div>
        <p className="text-3xl mb-2 text-primary font-bold">{header}</p>
        <ItemPerPage
          dataSet={dataSet}
          setPageby={setPageby}
          setPagenation={setPagenation}
          setCurrentPage={setCurrentPage}
          setTotalPages={setTotalPages}
        />
        {renderNotificationTable()}
        <Pagenation
          pagenation={pagenation}
          setPagenation={ setPagenation }
          setCurrentPage={ setCurrentPage }
          pageby={ pageby }
          currentPage={ currentPage}
          totalPages={ totalPages }
          length={ dataSet.length}
        />
      </div>
    );
  };

export default NotificationByPad