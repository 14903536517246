import React from 'react';
import { useState, useEffect } from 'react'; 
import Pagenation from '../../util/pagenation';
import { scheduleToOjectArray } from '../../util/assembleData';
import ItemPerPage from '../../util/itemPerPage';
import '../../styles/activitySubmitted.css'
const ActivitySubmitted = ({ activities }) => {
  const [init, setInit] = useState(false)
  const [pagenation, setPagenation] = useState({ start: 0, end: 10 });
  const [pageby, setPageby] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [listType, setListType] = useState('all');
  const [selectedDataSet, setSelectedDataSet] = useState([]);
  const [pagenatedDataSet, setPagenatedDataSet] = useState([]);
  const [toggleSchedule, setToggleSchedule] = useState({})
  const [subPagenation, setSubPagenation] = useState({}) 
  const [subPageby, setSubPageby] = useState({}) 
  const [subCurrentPage, setSubCurrentPage] = useState({}) 
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    if(activities) setSelectedDataSet(activities)
  }, [activities])
  
  useEffect(() => {
    if (selectedDataSet) {
      setPagenatedDataSet(selectedDataSet.slice(pagenation.start, pagenation.end));
    }
  }, [selectedDataSet, pagenation, listType])

  useEffect(() => {
    if (activities.length > 0 && !init) {
      setupSubPagenation(activities)
      setInit(true)
    }
  }, [activities, init])
  
  const setupSubPagenation = (activities) => {
    if (activities) {
      setLoading(true)
      let subpn = {}
      let subpb = {}
      let subcp = {}      

      activities.forEach((data) => { 
        const { _id, body = {} } = data
        const { schedule = [] } = body
        schedule.forEach((sche, index) => {
          subpn = { ...subpn, [`${_id}`]: { start: 0, end: 10 } }
          subpb = { ...subpb, [`${_id}`]: 10 }
          subcp = { ...subcp, [`${_id}`]: 1 }
          
       })
      })
      
      setSubPagenation(subpn)
      setSubPageby(subpb)
      setSubCurrentPage(subcp)
      
    }
    setLoading(false)
  }

  
  if (activities == null) return null
  if( Array.isArray(activities) && !activities.length) return null
  if (!Array.isArray(activities) && typeof activities === "object") {
    return null
  }
 
  const success = activities.filter(activity => activity.response_code === 200)
  const failed = activities.filter(activity => activity.response_code !== 200)
 
  
  const handleListType = (type) => {
    setListType(type);
    setSelectedDataSet(type === 'all' ? activities : type === 'success' ? success : failed)
    setTotalPages(Math.ceil(selectedDataSet.length / pageby))
    setPagenation({ start: 0, end: pageby })
    setPagenatedDataSet(selectedDataSet.slice(0, pageby))
  }
  


  const renderScheduleDetails = ({ scheduleArray, _id }) => {
    
    const pagenatedScheduleArray = scheduleArray.slice(subPagenation[_id]?.start || 0, subPagenation[_id]?.end || 10)
    return (
      <>
        {toggleSchedule[_id]?.main && (
          <div className="w-full container">
          
            <h2 className="w-full text-left text-primary">Schedules Detail</h2>
            <div className="w-full flex justify-start tb-cells heads">
              <div>Pad</div>
              <div>Operators</div>
            </div>
          
            {
              pagenatedScheduleArray.length > 0 ? (
              pagenatedScheduleArray.map((sche, index) => (
              <div className="w-full" key={index}>
                <div
                  key={index}
                  className=" flex justify-start tb-cells"
                  onClick={() => setToggleSchedule({
                    ...toggleSchedule, [_id]: {
                      ...toggleSchedule[_id],
                      sub: {
                        [`${_id}-${sche.pad_id}`]: !toggleSchedule[_id]?.sub[`${_id}-${sche.pad_id}`]
                      }
                    }
                  }
                  )}
                >
                  <div className="header cursor-pointer">{sche.pad_id}</div>
                  <div className="header">{sche.schedules.length}</div>
                
                </div>
              
                {toggleSchedule[_id]?.sub[`${_id}-${sche.pad_id}`] && (
                  <>
                    <div className="w-full flex justify-end tb-cells heads">
                      <div>Operator</div>
                      <div>Tasks</div>
                    </div>
                    {sche.schedules.map((s, index) => (
                      <div key={`schedule_${index}`} className="w-full flex justify-end tb-cells">
                        <div >{s.operator_id}</div>
                        <div >{s.task ? s.task : 'N/A'}</div>
                      </div>
                    ))}
                  
                  </>)}
                
              </div>
            
              ))) : (
                  <div className='p-2'>No data available</div>
              )}
            
            {
              scheduleArray.length > 10 && (
              <Pagenation
                pagenation={subPagenation[`${_id}`]}
                setPagenation={({ start, end }) => setSubPagenation({ ...subPagenation, [`${_id}`]: { start, end } })}
                setCurrentPage={(page) => setSubCurrentPage({ ...subCurrentPage, [`${_id}`]: page })}
                pageby={subPageby[`${_id}`]}
                currentPage={subCurrentPage[`${_id}`]}
                totalPages={Math.ceil(scheduleArray.length / subPageby[`${_id}`] || 10)}
                length={scheduleArray.length}
              />)
            }
            
          </div>
        )}
        
      </>
    )
  }
  const renderTable = () => {
    return (
      <div className='w-full text-white activitySubmitted'>
        <h3 className="text-primary">Activity Submitted</h3>
        <table className="table w-full text-center">
          <thead className="cursor-pointer">
            <tr>
              <th onClick={()=>handleListType('all') }>All</th>
              <th onClick={()=>handleListType('success') }>Successed</th>
              <th onClick={()=>handleListType('failed') }>Failed</th>
            </tr>
          </thead>
          <tbody className="cursor-pointer text-white">
            <tr>
              <td onClick={()=>handleListType('all') }>{activities.length}</td>
              <td onClick={()=>handleListType('success') }>{success.length}</td>
              <td onClick={()=>handleListType('failed') }>{failed.length}</td>
            </tr>
          </tbody>
        </table>

        <h3 className="text-primary">Activities</h3>
        <div className="w-full flex justify-around tb-cells heads">
          <div>Frame</div>
          <div>Status</div>
          <div>Schedules</div>
          <div>Timestamp</div>
          <div className="doubleSize">Message</div>
        </div>

        <div className=" w-full text-center justify-around">
          {
          pagenatedDataSet.length > 0 ? (
            pagenatedDataSet.map((activity, index) => {
              const { _id, response_code, response_text, simulation_id, timestamp,frame } = activity
              const { schedule = [],workforce = [] } = activity.body
              let scheduleArray = []
              if (schedule.length > 0) {
                scheduleArray = scheduleToOjectArray(schedule);                
              }
              const ts = new Date(timestamp * 1000);
              
              return (
                <React.Fragment key={_id}>
                  <div className={`text-${response_code === 200 ? 'green-500' : 'red-500'} w-full flex justify-between tb-cells`}>
                    <div>{frame ? `Frame-${frame}` : 'Not Available'}</div>
                    <div>{response_code === 200 ? 'Success' : 'Failed'}</div>
                    <div className="cursor-pointer" onClick={() => setToggleSchedule({ ...toggleSchedule, [_id]: {
                      main: !toggleSchedule[_id]?.main,
                      sub: {
                        ...toggleSchedule[_id]?.sub,
                        [simulation_id]: !toggleSchedule[_id]?.sub[simulation_id]
                      }
                      }
                    }
                    )}>{schedule.length}</div>
                    
                    <div>{`${ts.getFullYear()}-${ts.getMonth() + 1}-${ts.getDate()} ${ts.getHours()}:${ts.getMinutes()}:${ts.getSeconds()}`}</div>
                    <div className="text-left doubleSize">{response_code !== 200 ? response_text : 'N/A'}</div>
                  </div>
                  { renderScheduleDetails({scheduleArray, _id})}
                </React.Fragment>
              )
            })):(
              <div>No data available</div>
            )
          }
        </div>
      </div>  
    )
  }

  return (
    <div>
      <ItemPerPage
        dataSet={selectedDataSet}
        setPageby={setPageby}
        setPagenation={setPagenation}
        setCurrentPage={setCurrentPage}
        setTotalPages={setTotalPages}
      />
      {!loading && renderTable()}
      {loading && <div>Loading...</div>}
      <Pagenation
      pagenation={pagenation}
      setPagenation={ setPagenation }
      setCurrentPage={ setCurrentPage }
      pageby={ pageby }
      currentPage={ currentPage}
      totalPages={ Math.ceil(selectedDataSet.length / pageby || 10) }
      length={ selectedDataSet.length}
    />
    </div>
  )
}

export default ActivitySubmitted
