import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

const VolumeFlaredByTime = ({ productionData }) => {
  console.log(`VolumeFlaredByTime : productionData`, productionData);
  const [groupedData, setGroupedData] = useState({});
  const [chartData, setChartData] = useState({ data: [], layout: {} });

  useEffect(() => {
    if (!productionData || !Array.isArray(productionData.data)) {

      return; // Early return if data is not correctly formatted
    }

    const newGroupedData = {};

    productionData.data.forEach((entry) => {
      const {
        metadata: { pad_id, well_id },
        values,
      } = entry;
      const wellKey = `${pad_id}-${well_id}`;

      values.forEach((value, index) => {
        const tick = index;
        const volumeFlared = value[2]; // Assuming index 2 is volumeFlared based on your schema

        if (!newGroupedData[tick]) {
          newGroupedData[tick] = {};
        }

        if (!newGroupedData[tick][wellKey]) {
          newGroupedData[tick][wellKey] = {
            well_id: wellKey,
            volume_flared: 0,
          };
        }

        newGroupedData[tick][wellKey].volume_flared += volumeFlared;
      });
    });

    setGroupedData(newGroupedData);

    const allTicks = Object.keys(newGroupedData);
    updateChartData(allTicks, newGroupedData);
  }, [productionData]);

  const updateChartData = (ticks, data) => {
    const series = [
      {
        x: ticks,
        y: ticks.map((tick) =>
          Object.values(data[tick]).reduce(
            (sum, well) => sum + well.volume_flared,
            0
          )
        ),
        type: "scatter",
        mode: "lines",
        fill: "tozeroy",
        name: "Volume Flared",
        line: { shape: "spline", smoothing: 0.1, color: "#FF6384" },
        marker: { size: 6 },
      },
    ];

    const layout = {
      title: {
        text: "Volume Flared by Frame",
        font: {
          family: "Arial, sans-serif",
          size: 18,
          color: "#ffffff",
        },
      },
      xaxis: {
        title: "Frame",
        tickfont: {
          color: "white",
        },
        gridcolor: "rgba(128, 128, 128, 0.3)",
        nticks: Math.min(ticks.length, 50), // Show more ticks for detailed view
      },
      yaxis: {
        title: "Volume Flared (mcf)",
        tickfont: {
          color: "white",
        },
        gridcolor: "rgba(128, 128, 128, 0.3)",
        nticks: 10,
      },
      showlegend: true,
      legend: {
        orientation: "h",
        x: 0,
        xanchor: "left",
        y: 1.1,
        font: {
          color: "#ffffff",
        },
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      font: {
        color: "#ffffff",
      },
      autosize: true,
      margin: { l: 60, r: 20, t: 50, b: 70 }, // Reduced right margin to minimize space between chart and controls
      modebar: {
        orientation: "v",
        x: 1,
        xanchor: "right",
        y: 1,
        yanchor: "top",
        bgcolor: "rgba(0, 0, 0, 0.3)",
      },
    };

    setChartData({ data: series, layout });
  };

  return (
    <div className="rounded-sm p-2 shadow-sm relative">
      {chartData.data.length > 0 && (
        <Plot
          data={chartData.data}
          layout={chartData.layout}
          style={{ width: "100%", height: "450px" }}
          useResizeHandler={true}
          config={{
            scrollZoom: true,
            displaylogo: false,
            responsive: true,
            displayModeBar: true, // Always show the modebar
          }}
        />
      )}
    </div>
  );
};

export default VolumeFlaredByTime;
