import React, { useEffect, useState } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  Handle,
  useNodesState,
  useEdgesState,
} from 'reactflow';
import 'reactflow/dist/style.css';

const getNodeColor = (node) => {
  switch (node.type) {
    case 'pad':
      return '#AEDFF7';
    case 'well':
      return '#FEBE10';
    case 'oilTank':
      return '#D3D3D3';
    case 'waterTank':
      return '#ADD8E6';
    default:
      return '#E5E5E5';
  }
};

const PadNode = ({ data }) => (
  <div className="p-2 bg-blue-500 rounded-md text-white">
    <Handle type="target" position="top" style={{ borderRadius: 0 }} />
    <div className="font-bold">{data.label}</div>
    <div>Gas Pipeline: {data.gasPipeline}</div>
    <div>Oil Spilled: {data.oilSpilledVolume} bbl</div>
    <div>Water Spilled: {data.waterSpilledVolume} bbl</div>
    <Handle type="source" position="bottom" style={{ borderRadius: 0 }} />
  </div>
);

const WellNode = ({ data }) => (
  <div className="p-2 bg-yellow-500 rounded-md text-black">
    <Handle type="target" position="top" style={{ borderRadius: 0 }} />
    <div className="font-bold">{data.label}</div>
    <div>Oil Production: {data.oilProduction} bbl/d</div>
    <div>Gas Production: {data.gasProduction} mcf/d</div>
    {/* <div>Water Production: {data.waterProduction} bbl/d</div> */}
    <Handle type="source" position="bottom" style={{ borderRadius: 0 }} />
  </div>
);

const OilTankNode = ({ data }) => (
  <div className="p-2 bg-gray-400 rounded-md text-black">
    <Handle type="target" position="top" style={{ borderRadius: 0 }} />
    <div className="font-bold">Oil Tank</div>
    <div>Capacity: {data.capacity} bbl</div>
    <div>Volume: {data.volume} bbl</div>
    <Handle type="source" position="bottom" style={{ borderRadius: 0 }} />
  </div>
);

const WaterTankNode = ({ data }) => (
  <div className="p-2 bg-blue-300 rounded-md text-black">
    <Handle type="target" position="top" style={{ borderRadius: 0 }} />
    <div className="font-bold">Water Tank</div>
    <div>Capacity: {data.capacity} bbl</div>
    <div>Volume: {data.volume} bbl</div>
    <Handle type="source" position="bottom" style={{ borderRadius: 0 }} />
  </div>
);

const nodeTypes = {
  pad: PadNode,
  well: WellNode,
  oilTank: OilTankNode,
  waterTank: WaterTankNode,
};

const FrameDiagram = ({ frame }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedPad, setSelectedPad] = useState(null);

  useEffect(() => {
    if (frame && frame.pads.length > 0) {
      setSelectedPad(frame.pads[0].id); // Set the first pad as the default
    }
  }, [frame]);

  useEffect(() => {
    if (frame) {
      const pads = frame.pads || [];
      const newNodes = [];
      const newEdges = [];
      const spacingY = 200; // Increased spacing
      const spacingX = 350;
      const filteredPads = selectedPad ? pads.filter(pad => pad.id === selectedPad) : pads;
      let startYFrom = 0;

      filteredPads.forEach((pad, padIndex) => {
        const padId = `pad-${padIndex}`;
        let wellPadLen = pad.wells.length;
        let oilPadLen = pad.oil_tanks.length;
        let waterPadLen = pad.water_tanks.length;
        
        newNodes.push({
          id: padId,
          type: 'pad',
          data: {
            label: pad.name,
            gasPipeline: pad.gas_pipeline,
            oilSpilledVolume: pad.oil_spilled_volume,
            waterSpilledVolume: pad.water_spilled_volume
          },
          position: {
            x: spacingX,
            y: oilPadLen < wellPadLen ?
              parseInt(wellPadLen /2)* spacingY + startYFrom
              : parseInt(oilPadLen / 2) * spacingY + startYFrom
          },
          draggable: true,
        });
        
        pad.wells.forEach((well, wellIndex) => {
          const wellId = `well-${padIndex}-${wellIndex}`;
          const wellOffset = Math.floor(wellPadLen / 2) * spacingY;
          newNodes.push({
            id: wellId,
            type: 'well',
            data: {
              label: well.id,
              oilProduction: well.oil_daily_production_rate,
              gasProduction: well.gas_daily_production_rate,
            
            },
            position: {
              x: spacingX,
              y: wellIndex * spacingY - wellOffset
            },
            draggable: false,
            parentId: `pad-${padIndex}`,
            extent: 'parent',
          });
          newEdges.push({ id: `edge-${padId}-${wellId}`, source: padId, target: wellId, animated: true });
        });
        
        pad.oil_tanks.forEach((tank, tankIndex) => {
          const tankId = `oilTank-${padIndex}-${tankIndex}`;
          const oilOffest = Math.floor(oilPadLen / 2) * spacingY;
          newNodes.push({
            id: tankId,
            type: 'oilTank',
            data: {
              capacity: tank.capacity_per_tank,
              volume: tank.volume
            },
            position: {
              x: -1 * spacingX,
              y: tankIndex * spacingY - oilOffest
            },
            draggable: false,
            parentId: `pad-${padIndex}`,
            extent: 'parent',
          });
          newEdges.push({ id: `edge-${padId}-${tankId}`, source: padId, target: tankId, animated: true });
        });
        
        pad.water_tanks.forEach((tank, tankIndex) => {
          const tankId = `waterTank-${padIndex}-${tankIndex}`;
          const waterOffsetX = Math.floor(waterPadLen / 2) * spacingX;
          const waterOffsetY = oilPadLen < wellPadLen ?
            Math.ceil(wellPadLen / 2) * spacingY
            : Math.ceil(oilPadLen / 2) * spacingY;
          newNodes.push({
            id: tankId,
            type: 'waterTank',
            data: {
              capacity: tank.capacity_per_tank,
              volume: tank.volume
            },
            position: {
              x: waterOffsetX - (tankIndex * spacingX),
              y: waterOffsetY
            },
            draggable: false,
            parentId: `pad-${padIndex}`,
            extent: 'parent',
          });
          newEdges.push({ id: `edge-${padId}-${tankId}`, source: padId, target: tankId, animated: true });
        });
        
        startYFrom += wellPadLen < oilPadLen ?
          spacingY * oilPadLen + spacingY * 2
          : spacingY * wellPadLen + spacingY * 2
        
      });

      setNodes(newNodes);
      setEdges(newEdges);
    }
  }, [frame, selectedPad]);

  const handlePadChange = (event) => {
    setSelectedPad(event.target.value);
  };

  return (
    <div className="w-full h-screen bg-base-300 p-4">
      <div className="mb-4">
        <select
          id="pad-select"
          value={selectedPad || ""}
          onChange={handlePadChange}
          className="select select-bordered w-full max-w-xs"
        >
          <option value="">-- Select a pad --</option>
          {frame.pads.map((pad) => (
            <option key={pad.id} value={pad.id}>
              {pad.name}
            </option>
          ))}
        </select>
      </div>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        fitView
        nodesDraggable={true}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
      >
        <MiniMap nodeColor={node => getNodeColor(node)} />
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default FrameDiagram;
