const Pagenation = (({ pagenation, setPagenation, setCurrentPage, pageby, currentPage, totalPages, length }) => {
    return (
      <div className="flex justify-center p-4">
        <button
          className="mr-2 btn btn-primary"
          onClick={() => {
            if (pagenation.start > 0 && currentPage > 1 && pagenation.start > pageby && pagenation.end - pageby > 0) {
              setPagenation({ start: pagenation.start - pageby, end: pagenation.end - pageby });
              setCurrentPage(currentPage - 1);
            }
            else {
              setPagenation({ start: 0, end: pageby });
              setCurrentPage(1);
            }
          }}
        > {"< Prev "} </button>
        <span className="text-sm text-gray-500 p-3">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className='ml-2 btn btn-primary'
          onClick={() => {
            if (pagenation.end < length) {
              setPagenation({ start: pagenation.start + pageby, end: pagenation.end + pageby });
              setCurrentPage(currentPage + 1);
            }
          }}
        > {" Next > "} </button>
      </div>
    )
})

export default Pagenation
