import React from "react";
import Notifications from "./Notifications";

const MetricsDisplay = ({ oldFrame, newFrame }) => {
  // Utility function for calculating metrics
  const calculateMetrics = (data) => {
    if (!data || !data.pads || !data.operators) {
      const metricsTemplate = [
        "Total Pads",
        "Total Operators",
        "Operators Active",
        "Gas Pipelines",
        "Oil Pipelines",
        "Weather",
        "Notifications",
      ].map((title) => ({ title, value: "N/A" }));
      return metricsTemplate;
    }

    const { operators } = data;
    const totalOperators = operators.length;
    const operatorsOnDuty = operators.filter(
      (operator) => operator.hours_worked_in_shift > 0
    ).length;
    const weatherTemp = data.weather.mean_temperature;
    const is_raining = data.weather?.is_raining;
    return [
      { title: "Total Operators", value: totalOperators, icon: "👷" },
      { title: "Operators Active", value: operatorsOnDuty, icon: "⚙️" },
      {
        title: "Weather",
        value: weatherTemp + " °",
        icon: is_raining ? "🌧️" : "☀️",
      },
    ];
  };

  // Calculating metrics for old and new data frames
  const oldMetrics = calculateMetrics(oldFrame);
  const newMetrics = calculateMetrics(newFrame);

  // MetricCard component for displaying individual metric
  const MetricCard = ({ title, oldValue, newValue, icon, dataKey }) => {
    const formatValue = (value) => value.toLocaleString();

    return (
      <div key={dataKey} className="stats shadow bg-neutral text-base-content rounded-sm">
        <div className="stat">
          <div className="stat-figure text-info">
            <span className="text-lg">{icon}</span>
          </div>
          <div className="stat-title text-sm">{title}</div>
          <div className="stat-value text-sm">{formatValue(newValue)}</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 mt-2">
        {newMetrics.map((metric, index) => (
          <MetricCard
            dataKey={`metric-${metric.title}-${index}`}
            title={metric.title}
            oldValue={oldMetrics[index].value}
            newValue={metric.value}
            icon={metric.icon}
          />
        ))}

           <div className="stat">
            <div className="  text-info">
              <div className="indicator">
                <span className="indicator-item badge badge-secondary">
                  {newFrame.notifications.length}
                </span>
                <button
                  className="btn"
                  onClick={() =>
                    document.getElementById("notification_modal").showModal()
                  }
                >
                  <span className="animate-bounce">🔔</span> Show Notifications
                </button>
              </div>
           </div>
        </div>
      </div>

      {/* Notification Modal */}
      <dialog id="notification_modal" className="modal">
        <div className="modal-box relative">
          <button
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={() =>
              document.getElementById("notification_modal").close()
            }
          >
            ✕
          </button>
          <h3 className="font-bold text-lg mb-4 center">Notifications</h3>
          <Notifications notifications={newFrame.notifications} />
        </div>
      </dialog>
    </div>
  );
};

export default MetricsDisplay;
