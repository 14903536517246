import React from "react";
import Meta from "./../components/Meta";
function NotFoundPage(props) {
  return (
    <>
      <Meta title="404" />
      <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-br from-indigo-500 to-purple-700 text-white">
        <div className="mb-8">
          <h1 className="text-6xl font-bold mb-2">404</h1>
          <p className="text-2xl">Oops! Page Not Found</p>
        </div>
        <div className="bg-white-disabled bg-opacity-10 rounded-sm p-6">
          <p className="text-lg mb-4">
            The page <code className="bg-white-disabled bg-opacity-20 px-2 py-1 rounded">{props.location.pathname}</code> could not be found.
          </p>
          <a
            href="/"
            className="bg-white-disabled text-indigo-700 hover:bg-indigo-700 hover:text-white px-4 py-2 rounded-sm transition-colors duration-300"
          >
            Go Back Home
          </a>
        </div>
      </div>
    </>
  );
}

export default NotFoundPage;
