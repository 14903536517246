const getFrameOperation = (frame) => {  
  const iteration = frame.datetime
  const operatedTime = {morning : false, afternoon:false}
  if (iteration % 2 === 0) {
    operatedTime.morning = true
    const afternoonOperator = frame.operators.some((operator) => (operator.afternoon_credits > 0))
    if (afternoonOperator) {
      operatedTime.afternoon = true 
    }
  }
  else {
    operatedTime.afternoon = true
    const morningOperator = frame.operators.some((operator) => (
      operator.afternoon_credits > 0
      && 6 + operator.afternoon_credits < operator.hours_worked_in_shift + (operator.travelled_time / 60)
      && operator.hours_worked_in_shift + (operator.travelled_time / 60) < (8 + operator.afternoon_credits)
    ))
    
    if (morningOperator) {
      operatedTime.morning = true
    }
  }
  return operatedTime
}

export default getFrameOperation