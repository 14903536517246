import Plot from "react-plotly.js";
import {
  getProductVolumeSeries,
  getProductHandlingChartLayout
} from "../../util/assembleData";
const ProductHandlingChart = ({ data, config, commonLabels = {}, dataKey, targets, labels }) => {
  if(!data[dataKey]) return null
  return (
    <div className="p-2">
      <Plot 
        data={getProductVolumeSeries({
          data: targets?.map((target) => data[dataKey][target]) || [],
          labels,
          targets,
          config
        })}
        layout={
          getProductHandlingChartLayout({ commonLabels})
        }
        style={{ width: "100%", height: "450px" }}
        useResizeHandler={true}
        config={{
          scrollZoom: true,
          displaylogo: false,
          responsive: true,
          displayModeBar: true, // Always show the modebar
        }}
      />
    </div>
  )
}

export default ProductHandlingChart