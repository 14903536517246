import React from "react";
import Plot from "react-plotly.js";

const TankVolumeChart = ({ pads }) => {
  const getChartData = () => {
    const padIds = pads.map(pad => pad.id);

    const oilPercentages = pads.map(pad => {
      const totalOilCapacity = pad.oil_tanks.reduce((total, tank) => total + tank.capacity_per_tank, 0);
      const totalOilVolume = pad.oil_tanks.reduce((total, tank) => total + tank.volume, 0);
      return (totalOilVolume / totalOilCapacity) * 100;
    });

    const waterPercentages = pads.map(pad => {
      const totalWaterCapacity = pad.water_tanks.reduce((total, tank) => total + tank.capacity_per_tank, 0);
      const totalWaterVolume = pad.water_tanks.reduce((total, tank) => total + tank.volume, 0);
      return (totalWaterVolume / totalWaterCapacity) * 100;
    });

    return [
      {
        x: padIds,
        y: oilPercentages,
        type: 'bar',
        name: 'Oil Percentage',
        marker: { color: 'rgba(255, 127, 80, 0.8)' } // Coral color
      },
      {
        x: padIds,
        y: waterPercentages,
        type: 'bar',
        name: 'Water Percentage',
        marker: { color: 'rgba(30, 144, 255, 0.8)' } // Dodger blue color
      }
    ];
  };

  const layout = {
    title: 'Tank Volume Percentage per Pad',
    xaxis: {
      title: 'Pad ID',
      showgrid: false, // Remove grid lines
      zeroline: false, // Remove zero line
      showline: true, // Add axis line
      linecolor: 'rgba(255, 255, 255, 0.2)', // Light axis line color
    },
    yaxis: {
      title: 'Percentage',
      range: [0, 100],
      showgrid: true, // Add grid lines
      gridcolor: 'rgba(255, 255, 255, 0.1)', // Light grid line color
      zeroline: false, // Remove zero line
      showline: true, // Add axis line
      linecolor: 'rgba(255, 255, 255, 0.2)', // Light axis line color
      automargin: true // Auto-scale the y-axis
    },
    barmode: 'group',
    margin: { l: 60, r: 20, t: 50, b: 70 },
    legend: { orientation: "h", x: 0, xanchor: "left", y: 1.1 },
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    font: {
      color: "#ffffff", // Set text color to white
    },
  };

  const config = {
    displayModeBar: true,
    modeBarButtonsToRemove: ['sendDataToCloud'],
    displaylogo: false,
    responsive: true,
  };

  return (
    <div className="rounded-box text-white mb-6">
      {pads.length > 0 ? (
        <Plot
          data={getChartData()}
          layout={layout}
          config={config}
          style={{ width: "100%", height: "450px" }}
          useResizeHandler={true}
        />
      ) : (
        <div className="flex items-center justify-center h-64">
          <p>No data loaded yet.</p>
        </div>
      )}
    </div>
  );
};

export default TankVolumeChart;
