const MetricCard = ({ key, title, value, unit, icon, prefix = "" }) => {
  return (
    <div key={`${title}-${key}`} className="stats shadow bg-neutral  rounded-sm text-base-content">
      <div className="stat">
        <div className="stat-figure text-info">
          <span className="text-lg">{icon}</span>
        </div>
        <div className="stat-title text-sm">{title}</div>
        <div className="stat-value text-sm ">
          {prefix} {value} <span className="stat-desc text-xs badge">{unit}</span>
        </div>
      </div>
    </div>
  );
};

export default MetricCard;