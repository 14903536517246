import {useState, useEffect} from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";
import Pagenation from '../../util/pagenation';
import ItemPerPage from '../../util/itemPerPage';

const OperationSummaryPivotTable = ({ frame, operator_schedules, operators, isOverview, frameNum, frameName }) => {
  const [dataSet, setDataSet] = useState([]);
  const [originalDataSet, setOriginalDataSet] = useState([]);
  const [toggleAsset, setToggleAsset] = useState({});
  const [toggleOperator, setToggleOperator] = useState({});
  const [pagenation, setPagenation] = useState({ start: 0, end: 10 });
  const [pageby, setPageby] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({})
  const [toggleActiveOp, setToggleActiveOp] = useState(false)

  useEffect(() => {
    if (operators && operator_schedules) {
      isOverview ? mapScheduleToOperators(operator_schedules, operators) : mapOperatorToSchedule(operator_schedules, operators)
    }
  }, [operators, operator_schedules, isOverview])
  
  useEffect(() => {
    if (dataSet) {
      setTotalPages(Math.ceil(dataSet.length / pageby))
    }
  }, [dataSet, pageby])

  useEffect(() => { 
    if (!isOverview) {
      toggleActiveOp ?
        setDataSet(trimInactiveOperators(dataSet))
        : setDataSet(originalDataSet)
    }
  }, [toggleActiveOp, dataSet])

  const trimInactiveOperators = (data) => {
    
    if (!toggleActiveOp) {
      return data
    }
    return data.filter((operator) => operator.hours_worked_in_shift !== 0)
  }

  
  const mapScheduleToOperators = (operator_schedules, operators) => {
    let scheduleToOperators = [];
    operator_schedules.forEach((schedule) => {
      const asset = scheduleToOperators.find((os) => os.asset_id === schedule.asset_id);
      if (asset) {
        asset.operators.push(...operators.filter((operator) => operator.id === schedule.operator_id));
      }
      else {
        scheduleToOperators.push({ ...schedule, operators: operators.filter((operator) => operator.id === schedule.operator_id) });
      }
      
    })
    setDataSet(scheduleToOperators)
    setOriginalDataSet(scheduleToOperators)
  }

  const mapOperatorToSchedule = (operator_schedules, operators) => {
    const operatorToSchedules = operators.map((operator) => {
      return {
        ...operator,
        schedules: operator_schedules.filter((schedule) => schedule.operator_id === operator.id)
      }
    });
    setDataSet(operatorToSchedules)
    setOriginalDataSet(operatorToSchedules)
  }

  const renderOperator = (operator, i) => {
    return (
      <tr key={`${operator?.id}-${i}`}>
        <td>{operator?.id}</td>
        <td>{operator?.location}</td>
        <td>{operator?.hours_worked_in_shift?.toFixed(2)}</td>
        <td>{(operator?.travelled_time/60)?.toFixed(2)}</td>
        <td>NA</td>
        <td>{operator?.afternoon_credits?.toFixed(2)}</td>
        <td>{operator?.type}</td>
        <td>{operator?.capacity?.toFixed(2)}</td>
        <td>{operator?.volume?.toFixed(2)}</td>
      </tr>
    )
  }

  const renderSchedule = (schedule, i) => {
    return (
      <tr key={`${schedule?.id}-${i}`} >
        <td>{schedule.asset_id}</td>
        <td>{ schedule.task }</td>
      </tr>
    )
  }

  const renderOverviewTable = (data, i) => {
    const init = {
      operators: false,
      frameWorked: false, 
      hoursWorked: false,
      hoursTraveled: false,
      taskCount: false,
      afterNoonCredits: false,
      type: false,
      capacity: false,
      volume: false
    }
    
    const handleClick = (assetId, target) => {
      setSortBy({ ...sortBy, [assetId]: { ...init, [target]: sortBy[assetId]? !sortBy[assetId][target] : true } })
    }
  
    return (
      <div key={`asset-${data.asset_id}-${i}`}>
        <div
          className="border-b border-primary p-2 flex justify-between items-center cursor-pointer"
          onClick={() => setToggleAsset({ ...toggleAsset, [data.asset_id]: !toggleAsset[data.asset_id] })}>
          <h2 className=" text-primary text-xl mb-2 font-bold">{data.asset_id}</h2>
          {toggleAsset[data.asset_id] ? <ChevronDownIcon className="w-5 h-5 text-primary"/>:<ChevronUpIcon className="w-5 h-5 text-primary"/>}
        </div>
        {toggleAsset[data.asset_id] && (
          <div className="bg-neutral rounded-sm p-2 text-white">
            <h3 className='text-xl mb-2 text-primary font-bold'>Operators</h3>
            <table className="table w-full text-center">
              <thead>
                <tr className="">
                  <th
                    onClick={() => handleClick(data.asset_id,'operators')}
                    className="cursor-pointer"
                  >
                    <span className="flex justify-center items-center">
                    Operator
                      {sortBy[data.asset_id]?.operators ?
                      <ChevronUpIcon className="w-6 h-5 pt-1"/>
                          : <ChevronDownIcon className="w-6 h-5 pt-1" />}
                    </span>
                  </th>
                  <th
                    className="cursor-pointer"
                    onClick={() => handleClick(data.asset_id,'frameWorked') }
                  >
                    <span className="flex justify-center items-center">
                      Frame Worked
                      {sortBy[data.asset_id]?.frameWorked ?
                      <ChevronUpIcon className="w-6 h-5 pt-1"/>
                        : <ChevronDownIcon className="w-6 h-5 pt-1" />}
                    </span>
                  </th>
                  <th
                    className="cursor-pointer"
                    onClick={() => handleClick(data.asset_id,'hoursWorked') }
                  >
                    <span className="flex justify-center items-center">
                      Hours Worked
                      {sortBy[data.asset_id]?.hoursWorked ?
                      <ChevronUpIcon className="w-6 h-5 pt-1"/>
                        : <ChevronDownIcon className="w-6 h-5 pt-1" />}
                    </span>
                  </th>
                
                  <th
                    className="cursor-pointer"
                    onClick={() => handleClick(data.asset_id,'hoursTraveled') }
                  >
                    <span className="flex justify-center items-center">
                    Hours of Travel
                      {sortBy[data.asset_id]?.hoursTraveled ?
                      <ChevronUpIcon className="w-6 h-5 pt-1"/>
                        : <ChevronDownIcon className="w-6 h-5 pt-1" />}
                    </span>
                  </th>
                  <th>Task Count</th>
                  <th
                    className="cursor-pointer"
                    onClick={() => handleClick(data.asset_id,'afterNoonCredits') }
                  >
                    <span className="flex justify-center items-center">
                    Afternoon Credit
                      {sortBy[data.asset_id]?.afterNoonCredits ?
                      <ChevronUpIcon className="w-6 h-5 pt-1"/>
                        : <ChevronDownIcon className="w-6 h-5 pt-1" />}
                    </span>
                  </th>
                  
                  <th
                    className="cursor-pointer"
                    onClick={() => handleClick(data.asset_id,'type') }
                  >
                    <span className="flex justify-center items-center">
                    Type
                      {sortBy[data.asset_id]?.type ?
                      <ChevronUpIcon className="w-6 h-5 pt-1"/>
                        : <ChevronDownIcon className="w-6 h-5 pt-1" />}
                    </span>
                  </th>
                  
                  <th
                    className="cursor-pointer"
                    onClick={() => handleClick(data.asset_id,'capacity') }
                  >
                    <span className="flex justify-center items-center">
                    Capacity
                      {sortBy[data.asset_id]?.capacity ?
                      <ChevronUpIcon className="w-6 h-5 pt-1"/>
                        : <ChevronDownIcon className="w-6 h-5 pt-1" />}
                    </span>
                  </th>
                  <th
                    className="flex justify-center items-center cursor-pointer"
                    onClick={() => handleClick(data.asset_id,'volume')}
                  >
                    Volume
                    {sortBy[data.asset_id]?.volume ?
                    <ChevronUpIcon className="w-6 h-5 pt-1"/>
                    : <ChevronDownIcon className="w-6 h-5 pt-1"/>}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.operators.map((operator, i) => {
                  return renderOperator(operator, i)
                })}
              </tbody>
            </table>
          </div>)}
      
      </div>
    )
  }

  const renderByFrameTable = (data, i) => {
    
    return (
      <div key={`${data.id}-${i}`}>
        <div
          className="border-b border-primary p-2 flex justify-between items-center cursor-pointer"
          onClick={() => setToggleOperator({ ...toggleOperator, [data.id]: !toggleOperator[data.id] })}>
          <h2 className=" text-primary text-xl mb-2 font-bold">{data.id}</h2>
          {toggleOperator[data.id] ? <ChevronDownIcon className="w-5 h-5 text-primary"/>:<ChevronUpIcon className="w-5 h-5 text-primary"/>}
        </div>
        {toggleOperator[data.id] && (
          <div className="bg-neutral rounded-sm p-2 text-white">
            <table className="table w-full text-center">
              <thead>
                <tr>
                  <th>Frame Worked</th>
                  <th>Hours Worked</th>
                  <th>Hours of Travel</th>
                  <th>Task Count</th>
                  <th>Afternoon Credit</th>
                  <th>Type</th>
                  <th>Capacity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{`Frame ${frameNum} - ${frameName}`}</td>
                  <td>{data.hours_worked_in_shift.toFixed(2)}</td>
                  <td>{(data.travelled_time/60).toFixed(2)}</td>
                  <td>{data.schedules.length}</td>
                  <td>{data.afternoon_credits.toFixed(2)}</td>
                  <td>{data.type}</td>
                  <td>{data.capacity}</td>
                </tr>
              </tbody>
            </table>
            {data.schedules.length > 0 && (
              <>
              <h3 className='text-xl mb-2 text-primary font-bold'>Schedules</h3>
                <table className="table w-full text-center">
                  <thead>
                    <tr>
                      <th>Asset</th>
                      <th>Task</th>
                    </tr>
                  </thead>
                  <tbody>
                  {data.schedules.map((schedule, i) => {
                    return renderSchedule(schedule,i)
                  })}
                  </tbody>
                </table>
              </>
            )}
          </div>)}
      
      </div>
    )
  }

const sortData = (data) => {
  const { asset_id } = data;
  const sortKey = sortBy[asset_id];

  if (sortKey) {
    const { operators, frameWorked, hoursWorked, hoursTraveled, taskCount, afterNoonCredits, type, capacity, volume } = sortKey;

    if (operators) {
      data.operators.sort((a, b) => sortChar(a, b, 'id'));
    } else if (frameWorked) {
      data.operators.sort((a, b) => sortChar(a, b, 'location'));
    } else if (hoursWorked) {
      data.operators.sort((a, b) => b.hours_worked_in_shift - a.hours_worked_in_shift);
    } else if (hoursTraveled) {
      data.operators.sort((a, b) => b.travelled_time - a.travelled_time);
    } else if (taskCount) {
      // Do nothing
    } else if (afterNoonCredits) {
      data.operators.sort((a, b) => b.afternoon_credits - a.afternoon_credits);
    } else if (type) {
      data.operators.sort((a, b) => sortChar(a, b, 'type'));
    } else if (capacity) {
      data.operators.sort((a, b) => b.capacity - a.capacity);
    } else if (volume) {
      data.operators.sort((a, b) => b.volume - a.volume);
    } else {
      data.operators.sort();
    }
  }

  return data;
}

  const sortChar = (a, b, key) => {
    if (a[key].toLowerCase() < b[key].toLowerCase()) return -1
    if (a[key].toLowerCase() > b[key].toLowerCase()) return 1
    return 0;
  }

  const toggleFilterByActiveOperator = () => {
    return (
      <div className="text-white flex items-center">
        <label className="mr-2" >Show only active operators</label>
        <div className={`${toggleActiveOp ? 'bg-primary' : 'bg-gray-300'} relative inline-block w-10 mr-2 align-middle select-none rounded-full w-10 h-5`} >
          <input onChange={ ()=> setToggleActiveOp(!toggleActiveOp)} type="checkbox" name="toggle" id="toggle" className="hidden" />
          <label for="toggle" className='toggle-button block overflow-hidden w-10 rounded-full cursor-pointer'>
            <span className={`toggle-dot absolute w-5 h-5 bg-white rounded-full shadow inset-y-0 ${toggleActiveOp ? 'right-0' : 'left-0'}`}></span>
          </label>
        </div>
      </div>
    )
  }

  /**
   * 
   * @returns Frames Worked | Hours of Work | Hours of Travel | Task Count | Average Time between Visit | Ryutaro Matsuda and any other values that are interesting based on the data
   */
  const renderTable = () => {
    const pagenatedDataSet = dataSet.slice(pagenation.start, pagenation.end);
    
    return (
      <div className="bg-neutral rounded-sm p-2 text-white">
        {pagenatedDataSet.map((data, i) => {
          let tableData = sortData(data)
        return isOverview? renderOverviewTable(tableData, i) : renderByFrameTable(tableData, i)
        })}
        <Pagenation
          pagenation={pagenation}
          setPagenation={setPagenation}
          setCurrentPage={ setCurrentPage}
          pageby={ pageby}
          currentPage={ currentPage}
          totalPages={totalPages }
          length={dataSet.length}
        />
    </div>)
 }
  
  if (!operator_schedules || !operators) return null
  
  return (
    <div>
      <h2 className="text-3xl mb-2 text-primary font-bold">{isOverview ? "Assets" : "Operators"}</h2>
      <div className="w-full">
        <div className="flex justify-between w-full">
          <ItemPerPage
            dataSet={dataSet}
            setPageby={setPageby}
            setPagenation={setPagenation}
            setCurrentPage={setCurrentPage}
            setTotalPages={setTotalPages}
          />
          {!isOverview && toggleFilterByActiveOperator()}
        </div>
        {renderTable()}
      </div>
    </div>
  )
}

export default OperationSummaryPivotTable