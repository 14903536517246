import {useEffect, useState} from 'react'
import { mapScheduleToOperatorsBasedOnPad } from '../../util/assembleData'
import Pagenation from '../../util/pagenation';
import ItemPerPage from '../../util/itemPerPage';
import '../../styles/activitySubmitted.css'

const OperationSummary = ({ data }) => {
  const [init, setInit] =useState(false)
  const [toggleDetail, setToggleDetail] = useState({})
  const [toggleSchedule, setToggleSchedule] = useState({})
  const [toggleOperator, setToggleOperator] = useState({})
  const [pagenation, setPagenation] = useState({ start: 0, end: 10 });
  const [pageby, setPageby] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedDataSet, setSelectedDataSet] = useState([]);
  const [pagenatedDataSet, setPagenatedDataSet] = useState([]);
  const [subPagenation, setSubPagenation] = useState({}) 
  const [subPageby, setSubPageby] = useState({}) 
  const [subCurrentPage, setSubCurrentPage] = useState({}) 
  useEffect(() => {
    if (data) {
      const mappedOperationsData = data.operations.map((op) => {
        const mappedPadSchedules = mapScheduleToOperatorsBasedOnPad(op.operator_schedules, op.operators)
        return {
          ...op,
          frame: op.datetime,
          pad_schedules: {
            well: mappedPadSchedules.filter((s) => s.asset_id.includes("WELL")),
            pad: mappedPadSchedules.filter((s) => s.asset_id.includes("PAD")),
          }
        }
      })
      setSelectedDataSet(mappedOperationsData)
    }
      
  }, [data])
  
  useEffect(() => {
    if (selectedDataSet) {
      setPagenatedDataSet(selectedDataSet.slice(pagenation.start, pagenation.end));
    }
  }, [selectedDataSet, pagenation])

  useEffect(() => {

    if (selectedDataSet?.length > 0 && !init) {
      setupSubPagenation(selectedDataSet)
      setInit(true)
    }
  }, [selectedDataSet, init])

  if (!data) return (<div className="flex justify-center p-10 text-white">Loading Data...</div>)
 
  
  
  const setupSubPagenation = (data) => {
    
    if (data) {
      
      let subpn = {}
      let subpb = {}
      let subcp = {}

    
      data.forEach((d) => {
        const {frame} = d
          subpn = { ...subpn, [`${frame}-PAD`]: { start: 0, end: 10 },[`${frame}-WELL`]: { start: 0, end: 10 } }
          subpb = { ...subpb, [`${frame}-PAD`]: 10, [`${frame}-WELL`]: 10}
          subcp = { ...subcp, [`${frame}-PAD`]: 1, [`${frame}-WELL`]: 1}
      })
      setSubPagenation(subpn)
      setSubPageby(subpb)
      setSubCurrentPage(subcp)
    }
  }
  
  const renderOperationSummaryTable = () => {
    return (
      <div className='w-full container operationSummary'>
        <h2 className='text-primary text-2xl  font-bold p-2'>Operation Summary</h2>
        <div className='w-full flex justify-evenly tb-cells heads'>
          <div>Frame</div>
          <div>Status</div>
          <div>Active workers</div>
          <div>Inactive workers</div>
          <div>Schedules</div>
        </div>
        {selectedDataSet.slice(pagenation.start, pagenation.end).map((mpd) => {
          const activeWorkers = mpd.operators.filter((op) => op.afternoon_credits > 0 || op.hours_worked_in_shift > 0);
          const inactiveWorkers = mpd.operators.filter((op) => op.afternoon_credits === 0 && op.hours_worked_in_shift === 0)
          const isActive = activeWorkers.length > 0
          const sortedPadSchedule = {
            pad: mpd.pad_schedules.pad.sort((a, b) => parseInt(b.asset_id.substring(3)) - parseInt(a.asset_id.substring(3))),
            well: mpd.pad_schedules.well.sort((a, b) => parseInt(b.asset_id.substring(3)) - parseInt(a.asset_id.substring(3)))
          }
          return (
            <div
              key={`operationSummary_${mpd.frame}`}
              className="cursor-pointer"
            >
              <div
                className={`w-full flex justify-evenly tb-cells border-b border-secondary ${isActive ? 'text-green-500' : 'text-red-500'} ${toggleDetail[mpd.frame]? 'bg-primary text-white': ''}`}
                onClick={() => setToggleDetail({ ...toggleDetail, [mpd.frame]: !toggleDetail[mpd.frame] })}
              >
                <div className="text-center">Frame-{mpd.frame}</div>
                <div className="text-center">{isActive ? 'Active' : 'Inactive'} </div>
                <div className="text-center">{activeWorkers.length}</div>
                <div className="text-center">{inactiveWorkers.length}</div>
                <div className="text-center">PAD: {mpd.pad_schedules.pad.length} | WELL: { mpd.pad_schedules.well.length}</div>
              </div>
              {toggleDetail[mpd.frame] && (
                <div className="border-primary border-b ">
                  <h3>Active Asset Schedules For Frame-{mpd.frame}</h3>
                  {sortedPadSchedule.pad.length > 0 && (
                    <div>
                    <h3>PADs</h3>
                  {sortedPadSchedule.pad.slice(subPagenation[`${mpd.frame}-PAD`].start,subPagenation[`${mpd.frame}-PAD`].end).map((ps) => {

                    return (
                      <div
                        key={`pad_schedule-${ps.asset_id}`}
                        className='border-b border-secondary'
                      >
                        <div
                          onClick={() => setToggleSchedule({ ...toggleSchedule, [`${mpd.frame}-${ps.asset_id}`]: !toggleSchedule[`${mpd.frame}-${ps.asset_id}`] })}
                          className={`w-full pl-4 pr-4 flex justify-between flex-row tb-cells text-white ${toggleSchedule[`${mpd.frame}-${ps.asset_id}`] ? 'bg-primary' : ''}`}
                        ><div>
                            {ps.asset_id}
                          </div>
                          <div>Number of schedules: {ps.schedules.length}</div>
                        </div>
                        {toggleSchedule[`${mpd.frame}-${ps.asset_id}`] && (
                          <table className="table w-full">
                            <thead>
                              <tr>
                                <th>Operator</th>
                                <th>Operator Type</th>
                                <th>Task</th>
                              </tr>
                            </thead>
                            <tbody>
                              {ps?.schedules?.map((padSchedules, index) => {
                                return (
                                  <tr
                                    key={`pad_schdule-${ps.asset_id}-${padSchedules.operator_id}-${index}`}
                                    className="tb-cells text-white"
                                  >
                                    <td>{padSchedules.operator_id}</td>
                                    <td>{padSchedules.operator_detail.type}</td>
                                    <td>{padSchedules.task} </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        )}
                      </div>
                    )
                  })}
                  {sortedPadSchedule.pad.length > 10 && (
                     <Pagenation
                     pagenation={subPagenation[`${mpd.frame}-PAD`]}
                     setPagenation={({ start, end }) => setSubPagenation({ ...subPagenation, [`${mpd.frame}-PAD`]: { start, end } })}
                     setCurrentPage={(page) => setSubCurrentPage({ ...subCurrentPage, [`${mpd.frame}-PAD`]: page })}
                     pageby={subPageby[`${mpd.frame}-PAD`]}
                     currentPage={subCurrentPage[`${mpd.frame}-PAD`]}
                     totalPages={Math.ceil(sortedPadSchedule.pad.length / subPageby[`${mpd.frame}-PAD`] || 10)}
                     length={sortedPadSchedule.pad.length}
                   />
                    )}
                  </div>)}
                  {sortedPadSchedule.well.length > 0 && (
                    <div>
                      <h3>WELLs</h3>
                      {sortedPadSchedule.well.slice(subPagenation[`${mpd.frame}-WELL`].start, subPagenation[`${mpd.frame}-WELL`].end).map((ps) => {

                        return (
                          <div
                            key={`pad_schedule-${ps.asset_id}`}
                            className='border-b border-secondary'
                          >
                            <div
                              onClick={() => setToggleSchedule({ ...toggleSchedule, [`${mpd.frame}-${ps.asset_id}`]: !toggleSchedule[`${mpd.frame}-${ps.asset_id}`] })}
                              className={`w-full pl-4 pr-4 flex justify-between flex-row tb-cells text-white ${toggleSchedule[`${mpd.frame}-${ps.asset_id}`] ? 'bg-primary' : ''}`}
                            ><div>
                                {ps.asset_id}
                              </div>
                              <div>Number of schedules: {ps.schedules.length}</div>
                            </div>
                            {toggleSchedule[`${mpd.frame}-${ps.asset_id}`] && (
                              <table className="table w-full">
                                <thead>
                                  <tr>
                                    <th>Operator</th>
                                    <th>Operator Type</th>
                                    <th>Task</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ps?.schedules?.map((padSchedules, index) => {
                                    return (
                                      <tr
                                        key={`pad_schdule-${ps.asset_id}-${padSchedules.operator_id}-${index}`}
                                        className="tb-cells text-white"
                                      >
                                        <td>{padSchedules.operator_id}</td>
                                        <td>{padSchedules.operator_detail.type}</td>
                                        <td>{padSchedules.task} </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            )}
                          </div>
                        )
                      })}
                      {sortedPadSchedule.well.length > 10 && (
                        <Pagenation
                          pagenation={subPagenation[`${mpd.frame}-WELL`]}
                          setPagenation={({ start, end }) => setSubPagenation({ ...subPagenation, [`${mpd.frame}-WELL`]: { start, end } })}
                          setCurrentPage={(page) => setSubCurrentPage({ ...subCurrentPage, [`${mpd.frame}-WELL`]: page })}
                          pageby={subPageby[`${mpd.frame}-WELL`]}
                          currentPage={subCurrentPage[`${mpd.frame}-WELL`]}
                          totalPages={Math.ceil(sortedPadSchedule.well.length / subPageby[`${mpd.frame}-WELL`] || 10)}
                          length={sortedPadSchedule.well.length}
                        />
                      )}
                    </div>)}
                    { sortedPadSchedule.pad.length === 0 && sortedPadSchedule.well.length === 0 && (
                        <div className="text-white text-center p-10">No Data Available</div>
                    )}
                </div>                
              )}
              
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      <ItemPerPage
          dataSet={selectedDataSet}
          setPageby={setPageby}
          setPagenation={setPagenation}
          setCurrentPage={setCurrentPage}
          setTotalPages={setTotalPages}
        />
        { renderOperationSummaryTable() }
        <Pagenation
          pagenation={pagenation}
          setPagenation={ setPagenation }
          setCurrentPage={ setCurrentPage }
          pageby={ pageby }
          currentPage={ currentPage}
          totalPages={ Math.ceil(selectedDataSet.length / pageby || 10) }
          length={selectedDataSet.length}
      />
    </>
  )
}

export default OperationSummary