import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";

function Cashflow({ frame, isOverview }) {
  const [cashflowDataExpenses, setCashflowDataExpenses] = useState({});
  const [cashflowDataIncomes, setCashflowDataIncomes] = useState({});
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalIncomes, setTotalIncomes] = useState(0);

  const [chartConfig, setChartConfig] = useState({
    showlegend: true,
    hovermode: "closest",
    colorway: ["#636efa", "#ef553b", "#00cc96", "#ab63fa", "#ffa15a"],
  });

  useEffect(() => {
    if (frame?.cashflow_statement) {
      const expenses = {
        //...frame.cashflow_statement["Calculated Cashflow Fields"]["Expenses"],
        ...frame.cashflow_statement["Direct LOE"],
        ...frame.cashflow_statement["Non-Controllable Expenses"],
        ...frame.cashflow_statement["Calculated Cashflow Fields"]["Taxes"] ,
      };

      const revenueData =
        frame.cashflow_statement["Calculated Cashflow Fields"]["Revenue"] || {};
      const revenues = {
        "Total Income": {
          "Gas Sales": parseInt(revenueData["GAS SALES ($)"] || 0),
          "NGL Sales": parseInt(revenueData["NGL SALES ($)"] || 0),
          "Oil Sales": parseInt(revenueData["OIL SALES ($)"] || 0),
        },
      };

      setCashflowDataExpenses(expenses);
      setCashflowDataIncomes(revenues);
      setTotalExpenses(calculateTotal(expenses));
      setTotalIncomes(calculateTotal(revenues["Total Income"]));
    } else {
      setCashflowDataExpenses(null);
      setCashflowDataIncomes(null);
    }
  }, [frame]);

  const calculateTotal = (data) => {
    return Object.values(data).reduce(
      (acc, value) => acc + (typeof value === "number" ? parseInt(value) : 0),
      0
    );
  };

  const renderSunburstChart = (data) => {
    const flattenedData = [];

    const traverseData = (obj, parentPath = "") => {
      Object.entries(obj).forEach(([key, value]) => {
        const currentPath = parentPath ? `${parentPath} / ${key}` : key;
        if (typeof value === "object" && value !== null) {
          traverseData(value, currentPath);
        } else if (typeof value === "number" && value !== 0) {
          flattenedData.push({ label: currentPath, value });
        }
      });
    };

    const getHight = (datalen) => {
      if (datalen < 4) {
        return 410
      }
      else if (datalen < 13) {
        return 600
      }
      return 700;
    }

    const getYOffset = (datalen) => {
      if (datalen < 4) {
        return -0.3;
      }
      else if (datalen < 13) {
        return -0.85;
      }
      return -1.2;
    
    }

    const removeUnnecessaryData = (data) => {
     
      const acceptedLabels = [
        'Total Income / Gas Sales',
        'Total Income / NGL Sales',
        'Total Income / Oil Sales',
        'COMPANY LABOR ($/frame)',
        'FUEL & UTILITIES ($/frame)',
        'CHEMICALS ($/frame)',
        'WATER HAULING & DISPOSAL ($/frame)',
        'WELL SERVICES ($/frame)',
        'VEHICLES ($/frame)',
        'CONTRACT LABOR / PROF SERV ($/frame)',
        'COMMUNICATIONS/MEASUREMENTS ($/frame)',
        'EH&S / REGULATORY ($/frame)',
        'RENTALS ($/frame)',
        'PRODUCTION SUPPLIES / MISC. ($/frame)',
        'REPAIRS AND MAINTENANCE ($/frame)',
        'ROAD REPAIR & MAINTENANCE ($/frame)',
        'GAS PROCESSING FEES',
        'OIL PROCESSING FEES',
        'NGL PROCESSING FEES',
        'AD VALOREM TAX',
        'SEVERANCE TAX',
        'TOTAL PRODUCTION & PROPERTY TAXES'
      ]

      const filteredData = data.filter((item) => acceptedLabels.includes(item.label))
      const maxLabelLength = filteredData.reduce((max, item) => {
        const trimedLabel = isOverview? item.label.replace('($/frame)', '') : item.label;
        return Math.max(max, trimedLabel.length)
      }, 0);
      const maxNumberLength = filteredData.reduce((max, { value }) => {
        const formattedValue = value.toLocaleString('en-US', { minimumFractionDigits: 2 });
        return Math.max(max, formattedValue.length)
      }, 0);
      
      const spaceNeeded = maxLabelLength + 15;
      return filteredData.map((item) => {
        return {
          label: isOverview ? item.label.replace('($/frame)', ''): item.label,
          value: Math.abs(parseInt(item.value)),
          legendLabel: getLine(item, spaceNeeded, maxLabelLength,maxNumberLength, isOverview)
        }
      })
    }
    const getLine = ({ value, label }, spaceNeeded, maxLabelLength, maxNumberLength, isOverview) => {
      const trimedLabel = isOverview ? label.replace('($/frame)', '') : label;
      const formattedValue = value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      const formattedValueLength = formattedValue.length;
      const spaceAfterLabel = maxLabelLength - trimedLabel.length;
      const spaceBeforeNumber = maxNumberLength - formattedValueLength + 10;
      return `${trimedLabel}: ${'-'.repeat(spaceAfterLabel)}${'-'.repeat(spaceBeforeNumber)} $ ${formattedValue}`
    }
    traverseData(data);
    const filteredData = removeUnnecessaryData(flattenedData);
    const chartWidth = document.getElementById('incomeChart')?.offsetWidth || 0;
    return (
      <Plot
        data={[
          {
            type: "pie", // "sunburst" | "pie"
            labels: filteredData.map((item) => item.legendLabel),
            text: filteredData.map((item) => `${item.label} \n $${item.value.toLocaleString()}`),
            textposition: "inside",
            parents: filteredData.map((item, index) => {
              const parentPath = item.label
                .split(" / ")
                .slice(0, -1)
                .join(" / ");
              const parentIndex = filteredData.findIndex(
                (d) => d.label === parentPath
              );
              return parentIndex === -1 ? "" : flattenedData[parentIndex].label;
            }),
            values: filteredData.map((item) => item.value),
            branchvalues: "total",
            marker: {
              colors: filteredData.map(
                () => "#" + Math.floor(Math.random() * 16777215).toString(16)
              ),
            },
            hovertext: filteredData.map(
              (item) => `${item.label}: $${item.value.toLocaleString()}`
            ),
            hoverinfo: "text",
            outsidetextfont: { size: 12 },
            textposition: "inside",
            insidetextorientation: "horizontal",
            outsidetextorientation: "horizontal",
          },
        ]}
        layout={{
          height: getHight(filteredData.length),
          width: chartWidth,
          autosize: true,
          margin: { t: 0, l: 0, r: 0, b: 0 },
          colorway: chartConfig.colorway,
          showlegend: chartConfig.showlegend,
          hovermode: chartConfig.hovermode,
          paper_bgcolor: "transparent",
          plot_bgcolor: "transparent",
          legend: {
            orientation: 'h',
            font: {
              size: '12px',
              color: "#ffffff",
              family: "monospace",
            },
            y:getYOffset(filteredData.length),
            yanchor: "bottom",
            itemsizing: "constant",
            entrywidth: chartWidth,
          },
        }}
        config={{
          displayModeBar: true,
          modeBarButtonsToRemove: [
            "toggleHorizontalScrollbar",
            "resetScale2d",
            "hoverClosestCartesian",
            "hoverCompareCartesian",
          ],
        }}
      />
    );
  };

  if (!cashflowDataExpenses && !cashflowDataIncomes) {
    return (
      <div className="alert alert-warning" role="alert">
        No cashflow data available.
      </div>
    );
  }

  return (
    <div className="flex flex-col md:flex-row gap-4 justify-center">
      <div id="incomeChart" className="w-full md:w-1/2 bg-base-200 p-4 rounded-lg">
        <h2 className="text-xl font-semibold text-white mb-4">
          Incomes:
          <span className="badge badge-info text-lg ml-2">
            ${totalIncomes.toLocaleString()}
          </span>
        </h2>
        {cashflowDataIncomes ? (
          renderSunburstChart(cashflowDataIncomes)
        ) : (
          <div>No income data available.</div>
        )}
      </div>
      <div id="expenseChart" className="w-full md:w-1/2 bg-base-200 p-4 rounded-lg">
        <h2 className="text-xl font-semibold text-white mb-4">
          Expenses:
          <span className="badge badge-info text-lg ml-2">
            ${totalExpenses.toLocaleString()}
          </span>
        </h2>
        {cashflowDataExpenses ? (
          renderSunburstChart(cashflowDataExpenses)
        ) : (
          <div>No expense data available.</div>
        )}
      </div>
    </div>
  );
}

export default Cashflow;
