import React from "react";
import Plot from "react-plotly.js";

export const WellProductionChart = ({ selectedFrame }) => {
  const getChartData = () => {
    if (!selectedFrame) {
      return [];
    }

    const pads = selectedFrame.pads;
    const padIds = pads.map((pad) => pad.id);

    const oilProductionRates = padIds.map((padId) => {
      const pad = pads.find((pad) => pad.id === padId);
      return pad.wells.reduce(
        (total, well) => total + (well.oil_daily_production_rate || 0),
        0
      );
    });

    const gasProductionRates = padIds.map((padId) => {
      const pad = pads.find((pad) => pad.id === padId);
      return pad.wells.reduce(
        (total, well) => total + (well.gas_daily_production_rate || 0),
        0
      );
    });

    return [
      {
        x: padIds,
        y: oilProductionRates,
        type: "bar",
        name: "Oil",
        marker: { color: "rgba(255, 127, 80, 0.8)" }, // Coral color
        offsetgroup: 1,
      },
      {
        x: padIds,
        y: gasProductionRates,
        type: "bar",
        name: "Gas",
        marker: { color: "rgba(30, 144, 255, 0.8)" }, // Dodger blue color
        offsetgroup: 2,
        yaxis: "y2",
      },
    ];
  };

  const layout = {
    title: "Well Production by Pad",
    xaxis: {
      title: "Pad ID",
      showgrid: false, // Remove grid lines
      zeroline: false, // Remove zero line
      showline: true, // Add axis line
      linecolor: "rgba(255, 255, 255, 0.2)", // Light axis line color
    },
    yaxis: {
      title: "Oil Production Rate (bbl)",
      showgrid: true, // Add grid lines
      gridcolor: "rgba(255, 255, 255, 0.1)", // Light grid line color
      zeroline: false, // Remove zero line
      showline: true, // Add axis line
      linecolor: "rgba(255, 255, 255, 0.2)", // Light axis line color
      automargin: true, // Auto-scale the y-axis
    },
    yaxis2: {
      title: "Gas Production Rate (mcf)",
      overlaying: "y",
      side: "right",
      showgrid: false,
      zeroline: false,
      showline: true,
      linecolor: "rgba(255, 255, 255, 0.2)",
    },
    barmode: "group",
    margin: { l: 60, r: 60, t: 50, b: 70 },
    legend: { orientation: "h", x: 0, xanchor: "left", y: 1.1 },
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    font: {
      color: "#ffffff", // Set text color to white
    },
  };

  const config = {
    displayModeBar: true,
    modeBarButtonsToRemove: ["sendDataToCloud"],
    displaylogo: false,
    responsive: true,
  };

  return (
    <div className="rounded-box text-white mb-6">
      {selectedFrame ? (
        <Plot
          data={getChartData()}
          layout={layout}
          config={config}
          style={{ width: "100%", height: "450px" }}
          useResizeHandler={true}
        />
      ) : (
        <div className="flex items-center justify-center h-64">
          <p>No data loaded yet.</p>
        </div>
      )}
    </div>
  );
};
