
const renderGasTable = (data) => {
  return (
    <table className="table w-full text-center">
      <thead>
        <tr>
          <th>Gas Sold</th>
          <th>Produced Gas</th>
          <th>Time</th>
        </tr>
      </thead>
      {data.map((item, index) => {
        return (
          <tr key={`gas_table_${index}`}>
            <td>{item.gas_sold_to_midstream.toLocaleString({maximumFractionDigits: 2, minimumFractionDigits: 2})}</td>
            <td>{item.produced_gas.toLocaleString({maximumFractionDigits: 2, minimumFractionDigits: 2})}</td>
            <td>{item.datetime}</td>
          </tr>
        )
      })}
    </table>
  )
}
const renderGasMeter = (data) => {
  return data && data.map((item, index) => {
    return Object.keys(item).map((key) => (
      <div key={`gas_meter_${index}`}>
        <h3 className='text-l mb-2 text-primary font-bold p-2'>{key}</h3>
        {renderGasTable(item[key])}  
      </div>
      
    ));
  })
}
const getMessage = (message) => {
  
  // pickup message before gas meter
  const gasMeterIndex = message.indexOf("GAS METER");
  let  trimmedMessage = gasMeterIndex > 0? message.substring(0,gasMeterIndex): message;
  const oilTankIndex = trimmedMessage.indexOf("OIL TANKS");
  trimmedMessage = oilTankIndex > 0 ? trimmedMessage.substring(0,oilTankIndex): trimmedMessage;
  const waterTankIndex = trimmedMessage.indexOf("WATER TANKS");
  trimmedMessage = waterTankIndex > 0 ? trimmedMessage.substring(0,waterTankIndex) : trimmedMessage;
  
  // Extract GAS METER array
  const gasMeterRegex = /GAS METER: \[(.+?)\]/g;
  const gasMeterMatches = message.match(gasMeterRegex);
  const cleanGasMatches = gasMeterMatches?.map(match =>match.replace(/'/g, '"').replace(/GAS METER: /, ""));
  const gasMeterArray = cleanGasMatches?.map(item => {
    if (item) {
      const jstr = JSON.parse(item);
      return jstr[0] ? { [jstr[0].well_id]: jstr }  : null
    }
  }) || [];

  // // Extract OIL TANKS value
  const oilTanksRegex = /OIL TANKS: (\d+\.\d+)%/;
  const oilTanksMatch = message.match(oilTanksRegex);
  const oilTanksValue = oilTanksMatch ? parseFloat(oilTanksMatch[1]) : null;

  // // Extract WATER TANKS value
  const waterTanksRegex = /WATER TANKS: (\d+\.\d+)%/;
  const waterTanksMatch = message.match(waterTanksRegex);
  const waterTanksValue = waterTanksMatch ? parseFloat(waterTanksMatch[1]) : null;


  return (
    <div>
      {gasMeterArray.length > 0 && renderGasMeter(gasMeterArray)}
      <p className="text-left">{trimmedMessage}</p>
      {oilTanksValue && <p className="text-right text-white">OIL TANKS: {oilTanksValue}%</p>}
      {waterTanksValue && <p className="text-right text-white">WATER TANKS: {waterTanksValue}%</p>}
      
    </div>
  )
}

export default getMessage