import React from "react";
import MetricCard from "./MetricCard";

const MetricsDisplay = ({ productionData, cashflowData }) => {
 
  // Function to calculate metrics
  const calculateMetrics = () => {
    if (!productionData || !Array.isArray(productionData.data)) {
      return [
        { title: "🛢️ Oil", value: "N/A", unit: "bbl" },
        { title: "💨 Gas", value: "N/A", unit: "mcf" },
        { title: "💧 Water", value: "N/A", unit: "bbl" },
        { title: "💨 Cashflow", value: "N/A", unit: "$" },
      ];
    }
    

    let totalProducedOil = 0;
    let totalProducedWater = 0;
    let totalProducedGas = 0;

    productionData.data.forEach((wellData) => {

      wellData.values.forEach((value) => {
        totalProducedOil += value[7]; // Assuming index 7 is oil
        totalProducedWater += value[8]; // Assuming index 8 is water
        totalProducedGas += value[6]; // Assuming index 6 is gas
      });
    });
    const cashflowValue = cashflowData['Calculated Cashflow Fields']? parseInt(cashflowData['Calculated Cashflow Fields']['Revenue']['GROSS REVENUE ($)'] - cashflowData['Calculated Cashflow Fields']['Expenses']['Total LOE Expenses Pre-Tax ($)']) : 0
    return [
      {
        title: "Oil",
        value: parseInt(totalProducedOil).toLocaleString(),
        unit: "bbl",
        icon: "🛢️",
      },
      {
        title: "Gas",
        value: parseInt((totalProducedGas / 1000)).toLocaleString(),
        unit: "mcf",
        icon: "💨",
      },
      {
        title: "Water",
        value: parseInt(totalProducedWater).toLocaleString(),
        unit: "bbl",
        icon: "💧",
      },
      {
        title: "Cashflow",
        value: parseInt(cashflowValue).toLocaleString(),
        prefix: "$",
        unit: "",
        icon: "💨",
      },
    ];
  };
  

  // Calculate metrics based on production data
  const metrics = calculateMetrics();
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 mt-2">
      {metrics.map((metric, index) => (
        <MetricCard
          dataKey={`metric-${metric.title}-${index}`}
          title={metric.title}
          value={metric.value}
          unit={metric.unit}
          prefix={metric.prefix}
          icon={metric.icon}
          />
        
      ))}
    </div>
  );
};

export default MetricsDisplay;
