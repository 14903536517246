import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

function CumulativeCashflowChart({ cashflowStatements }) {
  const [cumulativeData, setCumulativeData] = useState({ income: [], expenses: [], frames: [] });

  useEffect(() => {
    const processCashflowData = (statements) => {
      const income = [];
      const expenses = [];
      const frames = [];

      let cumulativeIncome = 0;
      let cumulativeExpenses = 0;

      statements.forEach((statement, index) => {
        const frameNumber = index + 1; // Use index as the frame number
        frames.push(frameNumber);

        // If statement["Calculated Cashflow Fields"] is not available, set revenue and expense to empty object
        if (!statement["Calculated Cashflow Fields"]) {
          statement["Calculated Cashflow Fields"] = {
            Revenue: {},
            Expenses: {},
          };
        }

        const revenue = statement["Calculated Cashflow Fields"].Revenue || {};
        const expense = statement["Calculated Cashflow Fields"].Expenses || {};

        const totalIncome = Object.values(revenue).reduce((sum, value) => sum + (parseFloat(value) || 0), 0);
        const totalExpenses = Object.values(expense).reduce((sum, value) => sum + (parseFloat(value) || 0), 0);

        cumulativeIncome += totalIncome;
        cumulativeExpenses += totalExpenses;

        income.push(cumulativeIncome);
        expenses.push(cumulativeExpenses);
      });

      setCumulativeData({ income, expenses, frames });
    };

    processCashflowData(cashflowStatements);
  }, [cashflowStatements]);

  return (
    <div className="rounded-sm shadow-sm p-2 relative">
      {cumulativeData.frames.length > 0 && (
        <Plot
          data={[
            {
              x: cumulativeData.frames,
              y: cumulativeData.expenses,
              type: "scatter",
              mode: "lines",
              fill: "tozeroy",
              name: "Expenses",
              line: { shape: "spline", smoothing: 0.1, color: "#d32f2f" },
              marker: { size: 6 },
            },
            {
              x: cumulativeData.frames,
              y: cumulativeData.income,
              type: "scatter",
              mode: "lines",
              fill: "tozeroy",
              name: "Income",
              line: { shape: "spline", smoothing: 0.1, color: "#7cb342" },
              marker: { size: 6 },
            },
          ]}
          layout={{
            title: {
              text: "Cumulative Cashflow Over Time",
              font: {
                family: "Arial, sans-serif",
                size: 18,
                color: "#ffffff",
              },
            },
            xaxis: {
              title: "Frame",
              tickfont: {
                color: "white",
              },
              gridcolor: "rgba(128, 128, 128, 0.3)",
              nticks: Math.min(cumulativeData.frames.length, 50), // Show more ticks for detailed view
            },
            yaxis: {
              title: "Amount",
              tickfont: {
                color: "white",
              },
              gridcolor: "rgba(128, 128, 128, 0.3)",
              nticks: 10,
            },
            showlegend: true,
            legend: {
              orientation: "h",
              x: 0,
              xanchor: "left",
              y: 1.1,
              font: {
                color: "#ffffff",
              },
            },
            plot_bgcolor: "transparent",
            paper_bgcolor: "transparent",
            font: {
              color: "#ffffff",
            },
            autosize: true,
            margin: { l: 60, r: 20, t: 50, b: 70 }, // Reduced right margin to minimize space between chart and controls
            modebar: {
              orientation: "v",
              x: 1,
              xanchor: "right",
              y: 1,
              yanchor: "top",
              bgcolor: "rgba(0, 0, 0, 0.3)",
            },
          }}
          style={{ width: "100%", height: "450px" }}
          useResizeHandler={true}
          config={{
            scrollZoom: true,
            displaylogo: false,
            responsive: true,
            displayModeBar: true, // Always show the modebar
          }}
        />
      )}
    </div>
  );
}

export default CumulativeCashflowChart;
