import React from "react";
import Plot from "react-plotly.js";

export const VolumeFlaredChart = ({ production_tally }) => {
  const getChartData = () => {
    if (!production_tally || production_tally.length === 0) {
      return [];
    }

    // Get unique pad IDs
    const padIds = [...new Set(production_tally.map((item) => item.pad_id))];

    // Calculate flared gas volumes by pad
    const gasVolumesByPad = padIds.map((padId) => {
      return production_tally
        .filter((item) => item.pad_id === padId)
        .reduce((total, item) => total + (item.flared_gas || 0), 0);
    });

    return [
      {
        x: padIds,
        y: gasVolumesByPad,
        type: "bar",
        name: "Gas Volume Flared",
        marker: { color: "rgba(54, 162, 235, 0.6)" },
      },
    ];
  };

  const layout = {
    title: "Volume Flared by Pad",
    xaxis: {
      title: "Pad ID",
      tickfont: {
        color: "white",
      },
      gridcolor: "rgba(128, 128, 128, 0.3)",
    },
    yaxis: {
      title: "Volume (mcf)",
      tickfont: {
        color: "white",
      },
      gridcolor: "rgba(128, 128, 128, 0.3)",
      rangemode: "tozero", // Ensure the axis starts at zero
    },
    margin: { l: 60, r: 20, t: 50, b: 70 },
    legend: { orientation: "h", x: 0, xanchor: "left", y: 1.1 },
    barmode: "group",
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    font: {
      color: "#ffffff",
    },
    modebar: {
      orientation: "h",
      x: 0,
      xanchor: "left",
      y: 1.2,
      yanchor: "top",
      bgcolor: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <div className="rounded-box">
      {production_tally && production_tally.length > 0 ? (
        <Plot
          data={getChartData()}
          layout={layout}
          style={{ width: "100%", height: "450px" }}
          useResizeHandler={true}
          config={{
            scrollZoom: true,
            displaylogo: false,
            responsive: true,
          }}
        />
      ) : (
        <div className="flex items-center justify-center h-64">
          <p className=" ">No data loaded yet.</p>
        </div>
      )}
    </div>
  );
};
