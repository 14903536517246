import { useState, useEffect } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";
import Pagenation from '../../util/pagenation';
import ItemPerPage from '../../util/itemPerPage';
import getMessage from '../../util/getMessage';
const NotificationTable = ({ notifications, isOverview }) => {

  const [dataSet, setDataSet] = useState([]);
  const [originalDataSet, setOriginalDataSet] = useState([]);
  const [pagenation, setPagenation] = useState({ start: 0, end: 10 });
  const [pageby, setPageby] = useState(10);
  const [subPageNation, setSubPagenation] = useState({});
  const [subPageby, setSubPageby] = useState({});
  const [subCurrentPage, setSubCurrentPage] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({})
  const [toggleNotification, setToggleNotification] = useState({})
  const [toggleSubNotification, setToggleSubNotification] = useState({})
  
  useEffect(() => {
    if (notifications) {
      const mappedNotifications = getMappedNotifications(notifications).filter((data) => data.notifications.length > 0)
      setOriginalDataSet(mappedNotifications)
      setDataSet(mappedNotifications)
      setupSubPagenation()
    }
  }, [notifications])

  useEffect(() => {
    if (dataSet) {
      setTotalPages(Math.ceil(dataSet.length / pageby))
      setPagenation({ start: 0, end: pageby })
    }
  }, [dataSet, pageby, notifications])
 
  const getMappedNotifications = (notifications) => {
    return notifications.map((notification, index) => {
      return {
        frameNo: index + 1,
        notifications: notification
      }
    })
  }

  const setupSubPagenation = () => {
    if (notifications) {
      let subpn = {}
      let subpb = {}
      let subcp = {}
      notifications.forEach((notification, index) => {
        subpn = { ...subpn, [`frame-${index}`]: { start: 0, end: 10 } }
        subpb = { ...subpb, [`frame-${index}`]: 10 }
        subcp = { ...subCurrentPage, [`frame-${index}`]: 1 }
    })
      setSubPagenation(subpn)
      setSubPageby(subpb)
      setSubCurrentPage(subcp)
    }
  }

  const renderNotifications = (notifications, frameKey) => {
    return notifications.map((obj) => {
      return Object.keys(obj).map((key, index) => {
        return (
          <div
            key={`notification-${key}`}
            className="full-w flex flex-col justify-between cursor-pointer p-2"
          >
            <div
              onClick={() => setToggleSubNotification({ ...toggleSubNotification, [`notification-${key}`]: !toggleSubNotification[`notification-${key}`] })}
              className="text-primary text-l p-2 font-bold hover:bg-primary hover:text-white"
            >
              {key}
            </div>
            {toggleSubNotification[`notification-${key}`] && (
              <table className="table w-full text-center">
                  <thead>
                  <tr
                    className="border-b border-primary"
                  >
                      <th>Asset</th>
                      <th>Pad</th>
                      <th>Operator</th>
                      <th>Pipeline</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {obj[key].map((notification, index) => {
                      return (
                        <tr
                          key={`notification-${key}-${index}`}
                          className="text-white border-b border-primary"
                        >
                          <td>{notification.asset_id}</td>
                          <td>{notification.pad_id}</td>
                          <td>{notification.operator_id}</td>
                          <td>{notification.pipeline}</td>
                          <td>{getMessage(notification.message)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
            )}
          </div>
        )
      })
    })
  }

  const getCombinedData = (notifications) => {
    return notifications.reduce((acc, notification) => {
      if (notification?.asset_id) {
        if (!acc[notification?.asset_id]) {
          acc[notification?.asset_id] = [notification]
        }
        acc[notification?.asset_id].push(notification)
      }
      else if(notification?.pad_id) {
        if (!acc[notification?.pad_id]) {
          acc[notification?.pad_id] = [notification]
        }
        acc[notification?.pad_id].push(notification)
      }
      else if(notification?.operator_id) {
        if (!acc[notification?.operator_id]) {
          acc[notification?.operator_id] = [notification]
        }
        acc[notification?.operator_id].push(notification)
      }
      return acc
    }, {})
  }

  const renderNotificationTable = () => {

    const pagenatedDataSet = dataSet.slice(pagenation.start, pagenation.end);
    return pagenatedDataSet.map(({notifications, frameNo}, index) => {
      const dataByPad = getCombinedData(notifications)
      const dataByPadArray = Object.keys(dataByPad).map((key) => ({ [key]: dataByPad[key] }))
      const pagenatedPadData = dataByPadArray.slice(subPageNation[`frame-${frameNo}`].start, subPageNation[`frame-${frameNo}`].end)
      const frameIndex = (currentPage -1 ) * pageby + frameNo
      return frameNo !== 0 && (
        <>
        <div
          key={`frame-${frameNo}`}
          className="border-b border-primary p-2 flex justify-between items-center cursor-pointer"
        >
          <div
            className="text-primary text-xl pb-2 font-bold w-full flex flex-space-between items-center"
              onClick={() => setToggleNotification({ ...toggleNotification, [`frame-${frameIndex}`]: !toggleNotification[`frame-${frameIndex}`] })}>
              <div className='w-full'>Frame {frameIndex}</div>
              <div>
                {toggleNotification[`frame-${frameIndex}`] ? <ChevronDownIcon className="w-5 h-5 text-primary" /> : <ChevronUpIcon className="w-5 h-5 text-primary" />}
              </div>
          </div>
        </div>
        { toggleNotification[`frame-${frameIndex}`] && renderNotifications(pagenatedPadData, `frame-${frameIndex}`) }
      </>
      )
    })
  }
  
  return (
    <div>
      <p className="text-3xl mb-2 text-primary font-bold">Notifications</p>
      <ItemPerPage
        dataSet={dataSet}
        setPageby={setPageby}
        setPagenation={setPagenation}
        setCurrentPage={setCurrentPage}
        setTotalPages={setTotalPages}
      />
      {renderNotificationTable()}
      <Pagenation
        pagenation={pagenation}
        setPagenation={ setPagenation }
        setCurrentPage={ setCurrentPage }
        pageby={ pageby }
        currentPage={ currentPage}
        totalPages={ totalPages }
        length={ dataSet.length}
      />
    </div>
  );
};
export default NotificationTable