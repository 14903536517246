import React from "react";
import "./../styles/global.css";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import Chat from "./../components/Chat";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";

function App(props) {
  return (
      <QueryClientProvider>
        <AuthProvider>
          <Chat />
          <Router>
            <>
              <Navbar />
              <Switch>
                <Route exact path="/" component={IndexPage} />
                {/* 
               <Route exact path="/about" component={AboutPage} />
               <Route exact path="/faq" component={FaqPage} /> */}
                <Route exact path="/contact" component={ContactPage} />
                {/* <Route exact path="/pricing" component={PricingPage} /> */}
                <Route exact path="/dashboard" component={DashboardPage} />
                <Route exact path="/auth/:type" component={AuthPage} />
                <Route
                  exact
                  path="/settings/:section"
                  component={SettingsPage}
                />
                <Route exact path="/legal/:section" component={LegalPage} />
                <Route component={NotFoundPage} />
              </Switch>

              <Footer
                size="md"
                bgImage=""
                bgImageOpacity={1}
                textColor=""
                sticky={true}
              />
            </>
          </Router>
        </AuthProvider>
      </QueryClientProvider>
   
  );
}

export default App;
