import React, { useState, useMemo } from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import Select from "react-select";

export const OperatorsSummaryTable = ({ operators = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedOperator, setExpandedOperator] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterType, setFilterType] = useState(null);

  const rowsPerPageOptions = [
    { value: 10, label: "10 rows" },
    { value: 20, label: "20 rows" },
    { value: 50, label: "50 rows" },
  ];

  const operatorTypes = [
    ...new Set(operators.map((operator) => operator.type)),
  ].map((type) => ({ value: type, label: type }));

  const filteredOperators = useMemo(() => {
    if (!filterType) return operators;
    return operators.filter((operator) => operator.type === filterType.value);
  }, [filterType, operators]);

  const totalPages = Math.ceil(filteredOperators.length / rowsPerPage);

  const toggleOperatorExpansion = (id) => {
    setExpandedOperator(expandedOperator === id ? null : id);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setExpandedOperator(null);
  };

  const handleRowsPerPageChange = (selectedOption) => {
    setRowsPerPage(selectedOption.value);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  const handleFilterTypeChange = (selectedOption) => {
    setFilterType(selectedOption);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const currentOperators = useMemo(() => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = currentPage * rowsPerPage;
    return filteredOperators.slice(start, end);
  }, [currentPage, rowsPerPage, filteredOperators]);

  const data = useMemo(() => currentOperators, [currentOperators]);

  const columns = useMemo(
    () => [
      {
        Header: "Operator ID",
        accessor: "id",
        Cell: ({ value }) => <strong className="text-white">{value}</strong>,
      },
      {
        Header: "Operator Type",
        accessor: "type",
        Cell: ({ value }) => (
          <span className="badge badge-secondary">{value}</span>
        ),
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ value }) => <span className="badge">{value || "N/A"}</span>,
      },
      {
        Header: "Hours Worked",
        accessor: "hours_worked_in_shift",
        Cell: ({ value }) => <strong className="text-white">{value}</strong>,
      },
      {
        Header: "Miles Driven",
        accessor: "travelled_distance",
        Cell: ({ value }) => (
          <strong className="text-white">{(value * 0.621371).toFixed(2)}</strong>
        ),
      },
      {
        Header: "Afternoon Credits",
        accessor: "afternoon_credits",
        Cell: ({ value }) => <strong className="text-white">{value}</strong>,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "var(--daisyui-bg-base-200)",
      color: "var(--daisyui-text-base-content)",
      borderColor: "var(--daisyui-border-base-200)",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "var(--daisyui-bg-base-200)",
      color: "var(--daisyui-text-base-content)",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--daisyui-text-base-content)",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "var(--daisyui-text-base-content)",
    }),
  };

  return (
    <div className="rounded-box bg-base-200 p-4 shadow-lg mb-6">
      <div className="flex justify-between items-center mb-4">
        <Select
          options={rowsPerPageOptions}
          defaultValue={rowsPerPageOptions[0]}
          onChange={handleRowsPerPageChange}
          className="w-1/4"
          styles={customStyles}
        />
        <Select
          options={operatorTypes}
          isClearable
          onChange={handleFilterTypeChange}
          className="w-1/4"
          placeholder="Filter by Operator Type"
          styles={customStyles}
        />
      </div>
      <table {...getTableProps()} className="table table-compact w-full">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="bg-base-300">
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="font-semibold cursor-pointer"
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            const operator = row.original;
            const rowProps = row.getRowProps();
            return (
              <React.Fragment key={operator.id}>
                <tr
                  key={rowProps.key}
                  {...rowProps}
                  onClick={() => toggleOperatorExpansion(operator.id)}
                  className={`cursor-pointer ${
                    index % 2 === 0 ? "bg-base-100" : "bg-base-200"
                  }`}
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
                {expandedOperator === operator.id && (
                  <tr
                    key={`${rowProps.key}-expanded`}
                    className={`bg-base-300 ${
                      index % 2 === 0 ? "bg-base-100" : "bg-base-200"
                    }`}
                  >
                    <td colSpan="6">
                      <div className="p-2">
                        <p>
                          <span className="font-semibold">Latitude:</span>{" "}
                          <strong className="text-white">
                            {operator.latitude}
                          </strong>
                        </p>
                        <p>
                          <span className="font-semibold">Longitude:</span>{" "}
                          <strong className="text-white">
                            {operator.longitude}
                          </strong>
                        </p>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-between mt-4">
        <button
          className="btn btn-primary"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="text-sm text-gray-500">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="btn btn-primary"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};
