const icons = { "Oil": "🛢️", "Water": "💧", "Gas": "💨", "Ngl": "💨"}
const generateMetricsData = ({ data, key, action }) => {
  
  if (!data || data === undefined) return;
  const keyData = data && data[key] !== undefined ? data[key] : {}
  let metrics = [];
  const types = /GAS|OIL|WATER|NGL/gi
  const units = /bbl|mcf|gallons/gi
  
  Object.entries(keyData).forEach(([key, value]) => {
    if (key.includes(action)) {
      let type = key.match(types)[0]
      type = type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
      metrics.push(
        {
          title: type,
          value: parseInt(value).toLocaleString(),
          unit: key.match(units)[0].toLowerCase(),
          icon: icons[type],
        }
      )
    }
  })
  return sortMetrics(metrics)
}

export const generatDetailData = ({ data }) => {
  if (!data || data === undefined) return;
  const productDetails = data['Production Volume'] ? data['Production Volume'] : {}
  const oildProduce = productDetails['GROSS OIL VOLUME PRODUCED (bbl/frame)'];
  const oilSold = productDetails['OIL VOLUME SOLD (bbl/frame)']
  const oildSpill = productDetails['OIL VOLUME SPILLED (bbl/frame)']
  const oilInTank = oildProduce - oilSold - oildSpill;

  const gasFlared = productDetails['GROSS GAS FLARED (mcf/frame)'];

  const waterProduce = productDetails['GROSS WATER VOLUME PRODUCED (bbl/frame)'];
  const waterSpill = productDetails['WATER VOLUME SPILLED (bbl/frame)'];
  const waterHauled = productDetails['WATER VOLUME DISPOSED (bbl/frame)'];
  const waterInTank = waterProduce - waterSpill - waterHauled;

  const detailData = [
    {
      title: 'Oil In Tank',
      value: parseInt(oilInTank).toLocaleString(),
      unit: 'bbl',
      icon: icons['Oil'],
    },
    {
      title: 'Gas Flared',
      value: parseInt(gasFlared).toLocaleString(),
      unit: 'mcf',
      icon: icons['Gas'],
    },
    
    {
      title: 'Water In Tank',
      value: parseInt(waterInTank).toLocaleString(),
      unit: 'bbl',
      icon: icons['Water'],
    },
    {
      title: 'Water Hauled',
      value: parseInt(waterHauled).toLocaleString(),
      unit: 'bbl',
      icon: icons['Water'],
    },
  ]

  
  return detailData
}

const sortMetrics = (metrics) => {
  const order = ['Oil','Gas', 'Ngl', 'Water']
  return metrics.sort((a, b) => order.indexOf(a.title) - order.indexOf(b.title))
}

export default generateMetricsData