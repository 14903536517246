import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";
import ByWellProductionChart from "./dashboard/ByWellProductionChart";
import ByPadProductionChart from "./dashboard/ByPadProductionChart";
import HeatmapChart from "./dashboard/HeatmapChart";
import generateMetricsData, { generatDetailData } from "../util/generateMetrxData";
import { OperatorsSummaryTable } from "./dashboard/OperatorsSummaryTable";
import { WellProductionChart } from "./dashboard/WellProductionChart";
import { VolumeFlaredChart } from "./dashboard/VolumeFlaredChart";
import { OperatorsSchedule } from "./dashboard/OperatorsSchedule";
import TankVolumeChatByFrame from "./dashboard/TankVolumeChatByFrame";
import MapByFigure from "./dashboard/MapByFigure";
import CashflowByFigure from "./dashboard/CashflowByFigure";
import CashflowTable from "./dashboard/CashflowTable";
import OverviewMetricsDisplay from "./dashboard/MetricsDisplay";
import ByFrameMetricsDisplay from "./dashboard/ByFrameMetricsDisplay";
import FrameDiagram from "./dashboard/FrameDiagram";
import CommonMetricsDisplay from "./dashboard/CommonMetricsDisplay";
import CumulativeCashflowChart from "./dashboard/CumulativeCashflowChart";
import VolumeFlaredByTime from "./dashboard/VolumeFlaredByTime.js";
import VolumeFlaredByPad from "./dashboard/VolumeFlaredByPad.js";
import assembleCashFlowData, { getProductHandlingData} from "../util/assembleData.js";
import OperationSummaryPivotTable from "./dashboard/OperationSummaryPivotTable";
import NotificationTable from "./dashboard/NotificationTable";
import getFrameOperation from "../util/frameOperation.js";
import MetrixDisplaySection from "./dashboard/MetrixDisplaySection";
import NotificationByPad from "./dashboard/NotificaitonByPad.jsx";
import ProductHandlingChart from "./dashboard/productHandlingChart.jsx";
import ActivitySubmitted from "./dashboard/ActivitySubmitted.jsx";
import OperationSummary from "./dashboard/OperationSummary";
import OperatorSummary from "./dashboard/OperatorSummary";

const baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
var httpSession = axios.create({ baseURL, withCredentials: true });

const SimulationView = () => {
  const [simulations, setSimulations] = useState([]);
  const [selectedSimulation, setSelectedSimulation] = useState(null);
  const [selectedSimulationInfo, setSelectedSimulationInfo] = useState(null);
  const [frames, setFrames] = useState([]);
  const [selectedFrame, setSelectedFrame] = useState(null);
  const [oldFrame, setOldFrame] = useState();
  const [productionSampleData, setProductionSampleData] = useState([]);
  const [historyProduction, setHistoryProduction] = useState();
  const [historyState, setHistoryState] = useState();
  const [producedOilData, setProducedOilData] = useState();
  const [gasSoldData, setGasSoldData] = useState();
  const [gasPriceData, setGasPriceData] = useState();
  const [cumulativeOilSpilledVolume, setCumulativeOilSpilledVolume] =
    useState();
  const [progress, setProgress] = useState(0);
  const [wellProductionRatioData, setWellProductionRatioData] = useState();
  const [wellProductionData, setWellProductionData] = useState();
  const [activeTab, setActiveTab] = useState("overview");
  const [isLoading, setIsLoading] = useState(false);
  const [defaultFrame, setDefaultFrame] = useState(null);
  const [overviewFrame, setOverviewFrame] = useState(null);
  const [productHandling, setProductHandling] = useState(null);
  const [cashflowStatements, setCashflowStatements] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [activities, setActivities] = useState([]);
  const [frameShift, setFrameShift] = useState({ morning: false, afternoon: false });
  const [overviewOperation, setOperation] = useState(null);
  // toggles 
  const [productionPerformance, setProductionPerformance] = useState(true);
  const [financialPerformance, setFinancialPerformance] = useState(true);
  const [gasFlared, setGasFlared] = useState(true);
  const [simulationMap, setSimulationMap] = useState(true);
  const [productionByFramePerformance, setProductionByFramePerformance] = useState(true);
  const [gasFlaredByFrame, setGasFlaredByFrame] = useState(true);
  const [simulationMapByFrame, setSimulationMapByFrame] = useState(true);
  const [financialPerformanceByFrame, setFinancialPerformanceByFrame] = useState(true);
  const [WorkforceSummary, setWorkforceSummary] = useState(true);
  const [performanceTable, setPerformanceTable] = useState(true);
  const [notificationTable, setNotificationTable] = useState(true);
  const [ActivitySubmittedTable, setActivitySubmittedTable] = useState(true);
  const [notificationByPadTable, setNotificationByPadTable] = useState(true);
  const [gasTanks, setGasTanks] = useState(true);
  const [operatorSummary, setOperatorSummary] = useState(true);
  
  const isSimulationSelected = selectedSimulation !== null;
  const isFrameSelected = selectedFrame !== null;
  const extraDelay = 5000;
  const MemoizedWellProductionChart = React.memo(
    WellProductionChart,
    (prevProps, nextProps) =>
      prevProps.wellProductionData === nextProps.wellProductionData &&
      prevProps.selectedFrame === nextProps.selectedFrame
  );

  const MemoizedVolumeFlaredChart = React.memo(
    VolumeFlaredChart,
    (prevProps, nextProps) =>
      prevProps.production_tally === nextProps.production_tally &&
      prevProps.onFrameChange === nextProps.onFrameChange
  );

  const MemoizedTankVolumeChatByFrame = React.memo(
    TankVolumeChatByFrame,
    (prevProps, nextProps) =>
      prevProps.pads === nextProps.pads &&
      prevProps.onFrameChange === nextProps.onFrameChange
  );

  const MemoizedMap = React.memo(
    MapByFigure,
    (prevProps, nextProps) => prevProps.frame === nextProps.frame
  );

  const MemoizedMapOverview = React.memo(
    MapByFigure,
    (prevProps, nextProps) => prevProps.frame === nextProps.frame
  );

  const MemoizedOperatorsSummaryTable = React.memo(OperatorsSummaryTable);
  const MemoizedOperatorsSchedule = React.memo(OperatorsSchedule);

  const formatDatetimeString = (datetime) => {
    const datetimeObj = new Date(datetime);
    const day = ("0" + datetimeObj.getUTCDate()).slice(-2);
    const month = ("0" + (datetimeObj.getUTCMonth() + 1)).slice(-2);
    const year = datetimeObj.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const fetchData = async (url, onSuccess, onError) => {
    setIsLoading(true);
    try {
      const response = await httpSession.get(url);
      if (response.status === 200) {
        onSuccess(response.data);
      } else {
        console.error(`Error fetching data from ${url}:`, response);
        onError(response);
      }
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
      onError(error);
    } finally {
      if (url.includes("production")) {
        setTimeout(() => {
          setIsLoading(false);
        }, extraDelay);
      } else {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData(
      "/api/simulations",
      (data) => {
        setSimulations(data);
        if (data.length > 0) {
          const frames = data.map((simulation) => ({
            simulation_id: simulation.simulation_id,
            total_count: simulation.number_of_frames,
          }));
          setFrames(frames);
        }
      },
      console.error
    );
  }, []);

  useEffect(() => {
    if (selectedSimulation) {
      const simulationInfo = simulations.find(
        (sim) => sim.simulation_id === selectedSimulation
      );
      setSelectedSimulationInfo(simulationInfo);
      fetchData(
        `/api/simulations/${selectedSimulation}/frames?last=true`,
        (data) => {
          setDefaultFrame(data.data[0]);
          
        },
        console.error
      );
    } else {
      setSelectedSimulationInfo(null);
      setDefaultFrame(null);
      setCashflowStatements(null); // Reset cashflow data
    }
  }, [selectedSimulation, simulations, frames]);

  useEffect(() => {
    if (selectedSimulation) {
      
      fetchData(
        `/api/simulations/${selectedSimulation}/operations`,
        (data) => {
          setOperation(data)
        },
        console.error
      );
    }
  },[selectedSimulation, simulations, frames])

  useEffect(() => {
    const fetchProductionData = async () => {
      if (selectedSimulation) {
        fetchData(
          `/api/simulations/${selectedSimulation}/production`,
          setProductionSampleData,
          console.error
        );
      }
    };

    fetchProductionData();
  }, [selectedSimulation]);

  const Tabs = React.memo(({ activeTab, onTabChange }) => (
    <div
      role="tablist"
      className="tabs tabs-boxed tabs-bordered tabs-center rounded-sm shadow-lg bg-neutral"
    >
      <input
        type="radio"
        name="my_tabs"
        role="tab"
        id="overview"
        className="tab hidden peer"
        checked={activeTab === "overview"}
        onChange={() => onTabChange("overview")}
      />
      <label
        htmlFor="overview"
        className={`tab cursor-pointer ${
          activeTab === "overview"
            ? "tab-active bg-primary text-white font-bold"
            : "text-primary hover:text-primary-focus"
        }`}
      >
        Overview
      </label>

      <input
        type="radio"
        name="my_tabs"
        role="tab"
        id="byFrame"
        className="tab hidden peer"
        checked={activeTab === "byFrame"}
        onChange={() => onTabChange("byFrame")}
      />
      <label
        htmlFor="byFrame"
        className={`tab cursor-pointer ${
          activeTab === "byFrame"
            ? "tab-active bg-primary text-white font-bold"
            : "text-primary hover:text-primary-focus"
        }`}
      >
        Performance by Frame
      </label>

      <input
        type="radio"
        name="my_tabs"
        role="tab"
        id="diagram"
        className="tab hidden peer"
        checked={activeTab === "diagram"}
        onChange={() => onTabChange("diagram")}
      />
      <label
        htmlFor="diagram"
        className={`tab cursor-pointer ${
          activeTab === "diagram"
            ? "tab-active bg-primary text-white font-bold"
            : "text-primary hover:text-primary-focus"
        }`}
      >
        Operations Network
      </label>
    </div>
  ));

  useEffect(() => {
    if (historyState && historyState.length > 0) {
      setCumulativeOilSpilledVolume({
        series: [
          {
            name: "Volume",
            data: historyState.map(
              (itemData) => itemData.cumulative_oil_spilled_volume
            ),
          },
        ],
        options: {
          chart: { type: "line", height: 350 },
          xaxis: {
            categories: historyState.map((itemData) =>
              formatDatetimeString(itemData.datetime)
            ),
          },
          yaxis: { labels: { formatter: (val) => Math.round(val) } },
        },
      });
    }
  }, [historyState]);

  useEffect(() => {
    console.log("Progress:", progress);
  }, [progress]);

  useEffect(() => {
    if (selectedFrame) {
      const currentFrame = selectedFrame;
      const wellProductionSeries = [
        {
          name: "Oil Production Rate",
          data: currentFrame.pads.flatMap((pad) =>
            pad.wells.map((well) => well.oil_daily_production_rate)
          ),
        },
        {
          name: "Gas Production Rate",
          data: currentFrame.pads.flatMap((pad) =>
            pad.wells.map((well) => well.gas_daily_production_rate)
          ),
        },
      ];

      setWellProductionData({
        series: wellProductionSeries,
        categories: currentFrame.pads.flatMap((pad) =>
          pad.wells.map((well) => well.id)
        ),
      });
    }
  }, [selectedFrame]);

  const handleSimulationChange = async (event) => {
    setSelectedSimulation(event.target.value);

    if (event.target.value) {
      const defaultFrameData = frames.find(
        (frame) => frame.simulation_id === event.target.value
      );
      if (defaultFrameData) {
        setDefaultFrame(defaultFrameData);
      }

      // Fetch cumulative cashflow data
      fetchData(
        `/api/simulations/${event.target.value}/cashflow`,
        (data) => {
          console.log('data cashflow', data)
          setCashflowStatements(data.cashflow_statements);
          setOverviewFrame(assembleCashFlowData(data));
          setProductHandling(getProductHandlingData(data))
        },
        console.error
      );

      fetchData(
        `/api/simulations/${event.target.value}/notifications`,
        (data) => {
          setNotifications(data.notifications);
        },
        console.error
      )

      fetchData(
        `/api/simulations/${event.target.value}/activity`,
        (data) => {        
          if (data) {
            setActivities(data?.sort((a, b) => new Date(b.timestamp * 1000).getTime() - new Date(a.timestamp * 1000).getTime()))
          }
        },
        console.error
      )
    } else {
      setFrames([]);
      setSelectedSimulationInfo(null);
      setDefaultFrame(null);
      setCashflowStatements(null); // Reset cashflow data
      setNotifications(null)
    }
  };

  const handleFrameChange = async (event) => {
    const selectedFrameDateTime = event.target.value;
    if (selectedFrameDateTime) {
      fetchData(
        `/api/simulations/${selectedSimulation}/frames?datetime=${selectedFrameDateTime}`,
        function (data) {
          data = data.data;
          if (data.length >= 2) {
            setSelectedFrame(data[1]);
            setOldFrame(data[0]);
            setFrameShift(getFrameOperation(data[1]));
          } else if (data.length === 1) {
            setSelectedFrame(data[0]);
            setOldFrame(null);
            setFrameShift(getFrameOperation(data[0]));
          } else {
            setSelectedFrame(null);
            setOldFrame(null);
            setFrameShift({morning: false, afternoon: false});
          }
        },
        console.error
      );
    } else {
      setSelectedFrame(null);
      setOldFrame(null);
    }
  };

  return (
    <div className="min-h-screen mb-8" data-theme="coffee">
      <div className="container mx-auto p-8">
        {isLoading && (
          <div className="fixed inset-0 z-50 flex justify-center items-center bg-base bg-opacity-80 backdrop-blur-sm">
            <div className="loader">
              <span className="loading loading-bars loading-lg text-primary"></span>
            </div>
          </div>
        )}

        <div className="divider p-4 text-bold text-primary"> Visualize the Simulation </div>

        <div className="grid grid-cols-4 gap-4 text-accent">
          <div className="col-span-4">
            <div className="w-full    rounded-sm mb-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <select
                    id="simulation-select"
                    value={selectedSimulation || ""}
                    onChange={handleSimulationChange}
                    className="select w-full bg-accent text-accent-content rounded-sm"
                  >
                    <option value="">-- Select a simulation --</option>
                    {Array.isArray(simulations) && simulations.length > 0 ? (
                      simulations.map((simulation) => (
                        <option
                          key={simulation.simulation_id}
                          value={simulation.simulation_id}
                        >
                          {simulation.scenario_id} | {simulation.simulation_id}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No simulations available
                      </option>
                    )}
                  </select>
                </div>
                {isSimulationSelected && selectedSimulationInfo && (
                  
                    
                  <div className="col-span-1 md:col-span-2   text-primary rounded-sm">
                    <div className="divider p-4 text-bold "> Scenario Details </div>
                    <p>
                      <strong>Scenario Name:</strong>{" "}
                      {selectedSimulationInfo.scenario_id}
                    </p>
                    <p>
                      <strong>Simulation ID:</strong>{" "}
                      {selectedSimulationInfo.simulation_id}
                    </p>
                    <p>
                      <strong>Date Created:</strong>{" "}
                      {formatDatetimeString(selectedSimulationInfo.timestamp)}
                    </p>
                    <p>
                      <strong>Total Frames:</strong>{" "}
                      {selectedSimulationInfo.number_of_frames || "N/A"}
                    </p>

                    <CommonMetricsDisplay frameData={defaultFrame} />
                  </div>
                  
                )}
              </div>
            </div>
            {isSimulationSelected ? (
              <>
                <Tabs activeTab={activeTab} onTabChange={setActiveTab} />
                {activeTab === "overview" && (
                  <>
                    <div className="divider p-4 text-bold text-primary"> Simulation Key Performance Indicators </div>
                    
                    <div className="my-4">
                      <OverviewMetricsDisplay
                        productionData={productionSampleData || []}
                        cashflowData={overviewFrame?.cashflow_statement || []}
                      />
                    </div>
                    <div className="my-2">

                      <MetrixDisplaySection
                        data={generateMetricsData({
                          data: overviewFrame?.cashflow_statement || [],
                          key: 'Production Volume',
                          action: 'SOLD',
                        })}
                        header="Product Sold"
                      />

                      <MetrixDisplaySection
                        data={generatDetailData({
                          data: overviewFrame?.cashflow_statement || [],
                        })}
                        header="Product Details"
                      />
                    </div>
                    
                    <div className="divider p-4 text-bold cursor-pointer text-primary" onClick={() => setProductionPerformance(!productionPerformance)}> Production Performance {productionPerformance ? <ChevronDownIcon className="w-10 h-10"/>:<ChevronUpIcon className="w-10 h-10"/>} </div>
                    {productionPerformance && (<div>
                      <div className="bg-neutral rounded-sm p-2 mt-4">
                        <ByWellProductionChart
                          productionData={productionSampleData || []}
                        />
                      </div>
                      <div className="bg-neutral rounded-sm p-2 mt-4">
                        <ByPadProductionChart
                          productionData={productionSampleData || []}
                        />
                      </div>
                      <div className="bg-neutral rounded-sm p-2 mt-4">
                        <HeatmapChart
                          productionData={productionSampleData || []}
                        />
                      </div>
                    </div>
                    
                    )}

                    <div className="divider p-4 text-bold cursor-pointer text-primary" onClick={() => setGasFlared(!gasFlared)}> Product Handling {gasFlared ? <ChevronDownIcon className="w-10 h-10"/>:<ChevronUpIcon className="w-10 h-10"/>}</div>
                    {gasFlared && (
                      <div>
                        <div className="bg-neutral rounded-sm p-2">
                          {productHandling && (
                            <>
                              <ProductHandlingChart
                                key="oil-spilled-hauled"
                                data={productHandling}
                                dataKey='oil'
                                targets={ ['spilled', 'hauled'] }
                                labels={[{
                                  header: 'Oil Spilled',
                                }, {
                                  header: 'Oil Hauled',
                                }
                                ]}
                                commonLabels={{
                                  x: 'Frame',
                                  y: 'Volume Spilled/Hauled (bbl)',
                                  header: 'Oil Spilled/Hauled (bbl)'
                                }}
                                config={[
                                  { type: 'bar', color: "rgba(54, 162, 235, 0.8)" },
                                  { type: 'bar', color: "rgba(255, 99, 132, 0.8)" }
                                ]}
                              />
                              <ProductHandlingChart
                                key='water-spilled-disposed'
                                data={productHandling}
                                dataKey='water'
                                targets={['spilled', 'disposed']}
                                labels={[{
                                    header: 'Water Spilled',
                                  },
                                  {
                                    header: 'Water Hauled',
                                  }
                                ]}
                                commonLabels={{
                                  x: 'Frame',
                                  y: 'Volume Spilled/Hauled (bbl)',
                                  header: 'Water Spilled/Hauled (bbl)'
                                }}
                                config={[
                                  { type: 'bar', color: "rgba(54, 162, 235, 0.8)" },
                                  { type: 'bar', color: "rgba(255, 99, 132, 0.8)" }
                                ]}
                              />
                              
                              <ProductHandlingChart
                                key='gas-flared'
                                data={productHandling}
                                dataKey='gas'
                                targets={['flared']}
                                labels={[{
                                  header: 'Gas Flared',
                                  x: 'Frame',
                                  y: 'Volume Flared (mcf)'
                                }]}
                                commonLabels={{
                                  header: 'Gas Flared',
                                  x: 'Frame',
                                  y: 'Volume Flared (mcf)'
                                }}
                                
                                config={[
                                  { type: 'bar', color: "rgba(54, 162, 235, 0.8)" }
                                ]}
                              />
                              <ProductHandlingChart
                                key='gas-piped'
                                data={productHandling}
                                dataKey='gas'
                                targets={['piped']}
                                labels={[{
                                  header: 'Gas Piped',
                                  x: 'Frame',
                                  y: 'Volume Piped (mcf)'
                                }]}
                                commonLabels = {{
                                  header: 'Gas Piped',
                                  x: 'Frame',
                                  y: 'Volume Piped (mcf)'
                                }}
                                config={[
                                  { type: 'bar', color: "rgba(54, 162, 235, 0.8)" }
                                ]}
                              />
                            </>
                          )}
                        </div>
                        <div className="bg-neutral rounded-sm p-2">
                          {cashflowStatements && (
                            <VolumeFlaredByTime
                              productionData={productionSampleData || []}
                            />
                          )}
                        </div>
                        <div className="bg-neutral rounded-sm p-2">
                          {cashflowStatements && (
                            <VolumeFlaredByPad
                              productionData={productionSampleData || []}
                            />
                          )}
                        </div>
                      </div>
                    )}

                    <div className="divider p-4 text-bold cursor-pointer text-primary" onClick={ () => setFinancialPerformance(!financialPerformance)}> Financial Performance {financialPerformance ? <ChevronDownIcon className="w-10 h-10"/>:<ChevronUpIcon className="w-10 h-10"/>}</div>
                    {financialPerformance && (<div>
                      <div className="bg-neutral rounded-sm p-2">
                      
                        {cashflowStatements && (
                          <div className="bg-neutral rounded-sm p-2 mt-8">
                            <div className="bg-neutral rounded-sm p-2">
                              <CumulativeCashflowChart
                                cashflowStatements={cashflowStatements}
                              />
                            </div>
                            <div className="bg-neutral rounded-sm p-2">
                              <CashflowByFigure frame={overviewFrame} isOverview={true} />
                            </div>
                            <div className="bg-neutral rounded-sm p-1">
                              <h2 className="text-center text-white text-xl">Cashflow Table</h2>
                              <CashflowTable frame={overviewFrame} isOverview={true} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>)}                    

                    <div className="divider p-4 text-bold cursor-pointer text-primary" onClick={()=>setSimulationMap(!simulationMap)}> Simulation Map {simulationMap ? <ChevronDownIcon className="w-10 h-10"/>:<ChevronUpIcon className="w-10 h-10"/>}</div>
                    {simulationMap && (
                    <div className="bg-neutral rounded-sm p-2 mt-4 mb-8">
                      <MemoizedMapOverview
                        oldFrame={null}
                        newFrame={defaultFrame}
                      />
                    </div>  
                    )}
                    <div className="divider p-4 text-bold cursor-pointer text-primary" onClick={()=>setActivitySubmittedTable(!ActivitySubmittedTable)}> Activity Submitted {notificationTable ? <ChevronDownIcon className="w-10 h-10"/>:<ChevronUpIcon className="w-10 h-10"/>}</div>
                    {ActivitySubmittedTable && (
                      <div className="bg-neutral rounded-sm p-2 mt-4">
                        <ActivitySubmitted
                          activities={activities}
                        />
                      </div>
                    )} 

                    <div className="divider p-4 text-bold cursor-pointer text-primary" onClick={()=>setNotificationTable(!notificationTable)}> Notifications (by Frame) {notificationTable ? <ChevronDownIcon className="w-10 h-10"/>:<ChevronUpIcon className="w-10 h-10"/>}</div>
                    {notificationTable && (
                      <div className="bg-neutral rounded-sm p-2 mt-4">
                        <NotificationTable
                          notifications={notifications}
                          isOverview={true}
                          isFrame={true}
                          header={"Notifications (by Frame)"}
                        />
                      </div>
                    )}
                    <div className="divider p-4 text-bold cursor-pointer text-primary" onClick={()=>setNotificationByPadTable(!notificationByPadTable)}> Notifications (by Pad) {notificationByPadTable ? <ChevronDownIcon className="w-10 h-10"/>:<ChevronUpIcon className="w-10 h-10"/>}</div>
                    {notificationByPadTable && (
                      <div className="bg-neutral rounded-sm p-2 mt-4">
                        <NotificationByPad
                          notifications={notifications}
                          isOverview={true}
                          isPad={true}
                          header={"Notifications (by Pad)"}
                        />
                      </div>
                    )}
                    <div className="divider p-4 text-bold cursor-pointer text-primary" onClick={()=>setPerformanceTable(!performanceTable)}> Asset Operation Summary {performanceTable ? <ChevronDownIcon className="w-10 h-10"/>:<ChevronUpIcon className="w-10 h-10"/>}</div>
                    {performanceTable && (
                      <div className="bg-neutral rounded-sm p-2 mt-4">
                        <OperationSummary
                          data={overviewOperation}
                        />                        
                      </div>
                    )}
                    <div className="divider p-4 text-bold cursor-pointer text-primary" onClick={()=>setOperatorSummary(!operatorSummary)}> Operator Performance Summary {operatorSummary ? <ChevronDownIcon className="w-10 h-10"/>:<ChevronUpIcon className="w-10 h-10"/>}</div>
                    {operatorSummary && (
                      <div className="bg-neutral rounded-sm p-2 mt-4">
                        <OperatorSummary
                          data={overviewOperation}
                        />                        
                      </div>
                    )}
                  </>
                )}

                {activeTab === "byFrame" && (
                  <>
                    <div className="my-2 flex justify-between">
                      <select
                        id="frame-select"
                        onChange={handleFrameChange}
                        className="select w-1/4 bg-accent text-accent-content rounded-sm"
                      >
                        <option value="">-- Select a frame --</option>
                        {Array.from(
                          Array(selectedSimulationInfo.number_of_frames || 0),
                          (_, index) => (
                            <option key={index + 1} value={index + 1}>
                              Frame {index + 1}
                            </option>
                          )
                        )}
                      </select>
                      <div className="flex justify-center items-center">
                        {frameShift.morning && (<label class="p-2 m-2 bg-primary rounded-sm text-white text-sm">Morning</label>)}
                        {frameShift.afternoon && (<label class="p-2 m-2 bg-secondary rounded-sm text-white text-sm">Afternoon</label>)}
                      </div>
                    </div>
                    {isFrameSelected ? (
                      <>
                        <div className="divider p-4 text-bold text-primary"> Simulation Key Performance Indicators </div>
                        <div className="rounded-sm my-4">
                          <ByFrameMetricsDisplay
                            oldFrame={oldFrame}
                            newFrame={selectedFrame}
                          />
                        </div>
                        <div className="divider p-4 text-bold cursor-pointer text-primary" onClick={() => setProductionByFramePerformance(!productionByFramePerformance)}> Production Performance {productionByFramePerformance ? <ChevronDownIcon className="w-10 h-10"/>:<ChevronUpIcon className="w-10 h-10"/>} </div>
                        {productionByFramePerformance && (
                          <>
                            <div className="bg-neutral rounded-sm p-2 mt-4">
                              <MemoizedWellProductionChart
                                wellProductionData={wellProductionData}
                                selectedFrame={selectedFrame}
                              />
                            </div>
                          </>)}
                        <div className="divider p-4 text-bold cursor-pointer text-primary"
                          onClick={() => setGasFlaredByFrame(!gasFlaredByFrame)}> Gas Flared {gasFlaredByFrame ? <ChevronDownIcon className="w-10 h-10" /> : <ChevronUpIcon className="w-10 h-10" />} </div>
                        {gasFlaredByFrame && (
                          
                          <div className="grid grid-cols-1 gap-4">
                            <div className="bg-neutral rounded-sm p-2 mt-4">
                              <MemoizedTankVolumeChatByFrame
                                pads={selectedFrame?.pads}
                                onFrameChange={handleFrameChange}
                              />
                            </div>
                            
                            <div className="bg-neutral rounded-sm p-2 mt-4">
                              <MemoizedVolumeFlaredChart
                                production_tally={selectedFrame?.production_tally}
                                onFrameChange={handleFrameChange}
                              />
                            </div>
                          </div>
                        )}
                        <div className="divider p-4 text-bold cursor-pointer text-primary" onClick={() => setSimulationMapByFrame(!simulationMapByFrame)}> Simulation Map {simulationMapByFrame ? <ChevronDownIcon className="w-10 h-10" /> : <ChevronUpIcon className="w-10 h-10" />}</div>
                        {simulationMapByFrame && (
                        <div className="bg-neutral rounded-sm p-2 mt-8 mb-14">
                          
                          <MemoizedMap
                            oldFrame={oldFrame}
                            newFrame={selectedFrame}
                          />
                        </div>  
                        )}
                        
                        <div className="divider p-4 text-bold cursor-pointer text-primary" onClick={ () => setFinancialPerformanceByFrame(!financialPerformanceByFrame)}> Financial Performance {financialPerformanceByFrame ? <ChevronDownIcon className="w-10 h-10"/>:<ChevronUpIcon className="w-10 h-10"/>}</div>
                        {financialPerformanceByFrame && (
                          <div className="bg-neutral rounded-sm p-2 mt-8">
                           
                            <div className="bg-neutral rounded-sm p-2">
                              <CashflowByFigure frame={selectedFrame} isOverview={false} />
                            </div>
                            <h2 className="text-center text-white text-xl">Cashflow Table</h2>
                            <div className="bg-neutral rounded-sm p-1">
                              <CashflowTable frame={selectedFrame} isOverview={false}/>
                            </div>
                          </div>
                        )}
                        <div className="divider p-4 text-bold cursor-pointer text-primary" onClick={()=>setPerformanceTable(!performanceTable)}> Operation Summary {performanceTable ? <ChevronDownIcon className="w-10 h-10"/>:<ChevronUpIcon className="w-10 h-10"/>}</div>
                        {performanceTable && (
                          <div className="bg-neutral rounded-sm p-2 mt-4">
                            <OperationSummaryPivotTable
                              operator_schedules={selectedFrame?.operator_schedules}
                              operators={selectedFrame?.operators}
                              isOverview={false}
                              frameNum={selectedFrame?.datetime}
                              frameName={selectedFrame?.base_name}
                            />
                          </div>
                        )}

                        {/* <div className="divider p-4 text-bold cursor-pointer text-primary" onClick={ () => setWorkforceSummary(!WorkforceSummary)}> Workforce Summary {WorkforceSummary ? <ChevronDownIcon className="w-10 h-10"/>:<ChevronUpIcon className="w-10 h-10"/>}</div>
                        {WorkforceSummary && (
                          <div className="bg-neutral rounded-sm p-2 mt-4">
                            <h1 className="text-2xl font-bold mb-4 text-accent-content">
                              Workforce Summary :
                            </h1>
                            <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
                              <div className="bg-neutral rounded-sm p-2 mt-4">
                                <MemoizedOperatorsSchedule
                                  frame={selectedFrame}
                                />
                              </div>
                              <div className="bg-neutral rounded-sm p-2 mt-4">
                                <MemoizedOperatorsSummaryTable
                                  operators={selectedFrame?.operators}
                                />
                              </div>
                            </div>
                          </div>
                        )} */}
                      </>
                    ) : (
                      <div className="bg-neutral rounded-sm p-2">
                        <p className="text-base-content">
                          Please select a frame to view the performance details.
                        </p>
                      </div>
                    )}
                  </>
                )}
                {activeTab === "diagram" && (
                  <>
                    <div className="my-2">
                      <select
                        id="frame-select"
                        onChange={handleFrameChange}
                        className="select w-1/4 bg-accent text-accent-content rounded-sm"
                      >
                        <option value="">-- Select a frame --</option>
                        {Array.from(
                          Array(selectedSimulationInfo.number_of_frames || 0),
                          (_, index) => (
                            <option key={index + 1} value={index + 1}>
                              Frame {index + 1}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    {isFrameSelected && (
                      <div className="bg-neutral rounded-sm p-2 mt-4 ">
                        <FrameDiagram frame={selectedFrame} />
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="bg-neutral rounded-sm p-2">
                <p className="text-base-content">
                  Please select a simulation to continue.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function DashboardSection(props) {
  return <SimulationView />;
}

export default DashboardSection;
