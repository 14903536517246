import React, { useState, useEffect } from "react";


function Cashflow({ frame, isOverview }) {
  const [cashflowData, setCashflowData] = useState({});
  const [expandedRows, setExpandedRows] = useState({});

  useEffect(() => {
    if (frame?.cashflow_statement) {
      let updatedData = { ...frame.cashflow_statement };
      
      // Create an ordered structure for the data
      const orderedData = {
        "Production Volume": updatedData["Production Volume"] || {},
        Revenue: updatedData["Calculated Cashflow Fields"]["Revenue"] || {},
        "Direct LOE": updatedData["Direct LOE"] || {},
        "Non-Controllable Expenses":
          updatedData["Non-Controllable Expenses"] || {},
        Taxes: updatedData["Calculated Cashflow Fields"]["Taxes"] || {},

        "Calculated Cashflow Fields": {
          "Equivalent Volumes (sold/frame)":
            updatedData["Calculated Cashflow Fields"][
              "Equivalent Volumes (sold/frame)"
            ] || {},
          Expenses: updatedData["Calculated Cashflow Fields"]["Expenses"] || {},
          Metrics: updatedData["Calculated Cashflow Fields"]["Metrics"] || {},
          "Net Calculations":
            updatedData["Calculated Cashflow Fields"]["Net Calculations"] || {},
        },

        "Spot Prices": updatedData["Spot Prices"] || {},
      };
      setCashflowData(orderedData);
    } else {
      setCashflowData(null);
    }
  }, [frame]);

  const toggleRow = (label) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };
  const ignoreInCalculations = [
    'GROSS REVENUE ($)',
    'Total Taxes ($)',
    'TOTAL BOEPD',
    // 'Total LOE Expenses Inc. Taxes ($)'
  ]
  const hideTotalInTable = [
    'Production Volume',
    'Calculated Cashflow Fields',
    'Spot Prices',
    
  ]

  const ignoreLabel = [
    'WATER HAULING & DISPOSAL ($/day)'
  ]

  const calculateTotalValue = (obj, level, objKey) => {
    let total = 0;
    for (const [key, value] of Object.entries(obj)) {
      if(ignoreInCalculations.includes(key)) continue
      if (typeof value === "object" && value !== null) {
        total += calculateTotalValue(value, level + 1, key);
      } else if (
        typeof value === "number" ||
        (typeof value === "string" && !isNaN(parseFloat(value)))
      ) {
        total += typeof value === "string" ? parseFloat(value) : value;
      }
    }
    
    return hideTotalInTable.some((k)=> k === objKey) ? null : total;
  }
  
  const removePerFrameFromProductionVolume = ({ key, currentLabel, parentLabel }) => {
    if (isOverview  ) {
      return {
        filteredCurrentLabel: currentLabel.replace('/frame', ''),
        filteredKey: key.replace('/frame', '')
      }
    }
    return {
      filteredCurrentLabel: currentLabel,
      filteredKey: key
    }
  }

  const renderTable = (data, parentLabel = "", level = 0) => {
    const rows = [];
    Object.entries(data).forEach(([key, value]) => {
      if (key === "datetime") return;
      if (ignoreInCalculations.some((k) => k === key)) return;
      if (ignoreLabel.includes(key)) return;
      const currentLabel = parentLabel ? `${parentLabel} / ${key}` : key;
      const hasChildren = typeof value === "object" && value !== null;
      const totalValue = hasChildren ? calculateTotalValue(value, level, key) : null;
      const { filteredCurrentLabel, filteredKey } = removePerFrameFromProductionVolume({ key, currentLabel, parentLabel })
      rows.push({
        label: filteredCurrentLabel,
        displayLabel: filteredKey,
        value: hasChildren
          ? totalValue
          : typeof value === "string"
          ? parseInt(value)
          : value,
        level,
        hasChildren,
        isExpanded: expandedRows[filteredCurrentLabel],
      });
      if (hasChildren && expandedRows[filteredCurrentLabel]) {
        rows.push(...renderTable(value, filteredCurrentLabel, level + 1));
      }
    });

    return rows;
  };

  if (!cashflowData) {
    return (
      <div className="p-2 shadow-lg rounded-lg bg-base-200 mx-auto max-w-7xl">
        <div className="text-center py-4">No data available.</div>
      </div>
    );
  }

  const rows = renderTable(cashflowData);
  const orderedRows = rows;
  
  
  return (
    <div className="p-2 shadow-lg rounded-lg bg-base-200 mx-auto max-w-7xl">
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th className="border-b-2 border-neutral-focus px-4 py-2 bg-neutral-focus text-neutral-content">
                Category
              </th>
              <th className="border-b-2 border-neutral-focus px-4 py-2 bg-neutral-focus text-neutral-content">
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            {orderedRows.map((row, index) => {              
              const isGreen = row.label.includes("Revenue") && row.level === 0;
              const isRed =
                (row.label.includes("Direct LOE") ||
                  row.label.includes("Non-Controllable Expenses") ||
                  row.label.includes("Taxes") ||
                  (row.label.includes("Calculated Cashflow Fields") &&
                    row.label.includes("Expenses"))) &&
                row.level === 0;
              const textColor = isGreen
                ? "text-green-600"
                : isRed
                ? "text-red-600"
                : "text-white";

              return (
                <React.Fragment key={index}>
                  <tr
                    className={`hover:bg-neutral ${
                      row.level === 0 ? "bg-neutral-focus" : "bg-base-100"
                    } ${index % 2 === 0 ? "bg-opacity-50" : "bg-opacity-75"}`}
                  >
                    <td
                      className={`border-b border-neutral px-4 py-2 ${textColor}`}
                      style={{ paddingLeft: `${row.level * 20}px` }}
                    >
                      {row.hasChildren && (
                        <button
                          className="mr-2 btn btn-xs btn-circle btn-outline btn-info"
                          onClick={() => toggleRow(row.label)}
                        >
                          {row.isExpanded ? "-" : "+"}
                        </button>
                      )}
                      <strong>{row.displayLabel.replace('(sold/frame)', '')}</strong>
                    </td>
                    <td
                      className={`border-b border-neutral px-4 py-2 ${getColorOfValue(row.label, row.value)}`}
                    >
                      {row.value !== null 
                      ? getValueWithUnit(row.displayLabel, row.value)
                        : row.level > 0
                        ? "0.00"
                        : ""}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
 
  function getColorOfValue(label, value) {
    const redLabels = ['Direct LOE', 'Non-Controllable Expenses', 'Taxes', 'Expenses']
    const isRed = redLabels.some((key) => label.includes(key));
    if (label.includes("Volume") ||
      label.includes("Production")) {
      return "text-white"
    }
    else if (isRed || value < 0) {
      return 'text-red-600'
    }
    else if (value >= 0) {
      return 'text-green-600'
    }

  }
  function getValueWithUnit(label, value) {
    const prefixes = [
      "($", "FEES", "Direct LOE", "TAX","Revenue", "Expenses", "Metrics", "Metrics", "Metrics", "/ BOE", "Net Calculations","Taxes"
    ];
    const EquiVol = ["BOE(gas)", "BOE(ngl)", "BOE(oil)"];
    
    const match = label.match(/\(([^)]+)\)/);
    const hasPrefix = prefixes.some((key) => label.includes(key));
    
    let val = hasPrefix ? "$" : "";
    val += Math.abs(value).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    if (label.includes('/ BOE') || label.includes('Metrics')) {
      val += " /BOE";
    }
    else if (EquiVol.some((e) => e === label)) {
      val += ` ${label}`;
    }
    else {
      val += match ? ' ' + match[1].replace("$", "").replace("/frame", "") : "";
    }
    
    return val;
  }
}

export default Cashflow;
