import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

const CumulativeProductionChart = ({ productionData }) => {
  const [groupedData, setGroupedData] = useState({});
  const [chartData, setChartData] = useState([]);
  const [selectedWell, setSelectedWell] = useState(null);
  const [wellOptions, setWellOptions] = useState([]);

  const [visibleSeries, setVisibleSeries] = useState({
    producedOil: true,
    producedWater: true,
    producedGas: true,
  });

  useEffect(() => {
    if (!productionData || !Array.isArray(productionData.data)) {

      return;
    }

    const newGroupedData = {};
    const wellSet = new Set();

    productionData.data.forEach((entry) => {
      const {
        metadata: { pad_id, well_id },
        values,
      } = entry;
      const wellKey = `${pad_id}-${well_id}`;
      wellSet.add(wellKey);

      values.forEach((value, index) => {
        const tick = index;
        const producedOil = value[7]; // Assuming index 7 is producedOil based on your schema
        const producedWater = value[8]; // Assuming index 8 is producedWater based on your schema
        const producedGas = value[6]; // Assuming index 6 is producedGas based on your schema

        if (!newGroupedData[tick]) {
          newGroupedData[tick] = {};
        }

        if (!newGroupedData[tick][wellKey]) {
          newGroupedData[tick][wellKey] = {
            produced_oil: 0,
            produced_water: 0,
            produced_gas: 0,
          };
        }

        newGroupedData[tick][wellKey].produced_oil += producedOil;
        newGroupedData[tick][wellKey].produced_water += producedWater;
        newGroupedData[tick][wellKey].produced_gas += producedGas;
      });
    });

    setGroupedData(newGroupedData);
    setWellOptions(Array.from(wellSet));

    const allTicks = Object.keys(newGroupedData);
    updateChartData(allTicks, newGroupedData);
  }, [productionData]);

  useEffect(() => {
    if (Object.keys(groupedData).length > 0) {
      const ticks = Object.keys(groupedData);
      updateChartData(ticks, groupedData);
    }
  }, [selectedWell, groupedData, visibleSeries]);

  const updateChartData = (ticks, data) => {
    let cumulativeOil = 0;
    let cumulativeWater = 0;
    let cumulativeGas = 0;

    const plotData = [
      {
        x: ticks,
        y: ticks.map((tick) => {
          const tickValue = selectedWell
            ? data[tick][selectedWell]?.produced_oil || 0
            : Object.values(data[tick]).reduce(
                (sum, well) => sum + well.produced_oil,
                0
              );
          cumulativeOil += tickValue;
          return cumulativeOil;
        }),
        type: "scatter",
        mode: "lines",
        fill: "tozeroy",
        name: "Oil",
        yaxis: "y1",
        visible: visibleSeries.producedOil ? true : "legendonly",
        line: { color: "#FF5733" }, // Distinct color for oil
      },
      {
        x: ticks,
        y: ticks.map((tick) => {
          const tickValue = selectedWell
            ? data[tick][selectedWell]?.produced_gas || 0
            : Object.values(data[tick]).reduce(
                (sum, well) => sum + well.produced_gas,
                0
              );
          cumulativeGas += tickValue;
          return cumulativeGas;
        }),
        type: "scatter",
        mode: "lines",
        fill: "tozeroy",
        name: "Gas",
        yaxis: "y2",
        visible: visibleSeries.producedGas ? true : "legendonly",
        line: { color: "#33FF57" }, // Distinct color for gas
      },
      {
        x: ticks,
        y: ticks.map((tick) => {
          const tickValue = selectedWell
            ? data[tick][selectedWell]?.produced_water || 0
            : Object.values(data[tick]).reduce(
                (sum, well) => sum + well.produced_water,
                0
              );
          cumulativeWater += tickValue;
          return cumulativeWater;
        }),
        type: "scatter",
        mode: "lines",
        fill: "tozeroy",
        name: "Water",
        yaxis: "y1",
        visible: visibleSeries.producedWater ? true : "legendonly",
        line: { color: "#3357FF" }, // Distinct color for water
      },
    ];

    setChartData(plotData);
  };

  const handleWellChange = (event) => {
    setSelectedWell(event.target.value);
  };

  const toggleSeriesVisibility = (seriesName) => {
    setVisibleSeries((prevState) => ({
      ...prevState,
      [seriesName]: !prevState[seriesName],
    }));
  };

  return (
    <div className="rounded-sm shadow-sm p-2 relative">
      <div className="mb-4 flex items-center justify-end">
        <select
          id="wellSelect"
          className="mt-1 block w-1/3 pl-3 py-2 bg-base-200 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={selectedWell || ""}
          onChange={handleWellChange}
        >
          <option value="">All Wells</option>
          {wellOptions.map((well) => (
            <option key={well} value={well}>
              {well}
            </option>
          ))}
        </select>
      </div>
      {chartData.length > 0 && (
        <Plot
          data={chartData}
          layout={{
            title: "Cumulative Production over time (Frame)",
            xaxis: {
              title: "Frame",
              tickfont: {
                color: "white",
              },
              gridcolor: "rgba(128, 128, 128, 0.3)",
              nticks: 20,
            },
            yaxis: {
              title: "Volume (bbl)",
              tickfont: {
                color: "white",
              },
              gridcolor: "rgba(128, 128, 128, 0.3)",
              nticks: 10,
            },
            yaxis2: {
              title: "Volume (mcf)",
              tickfont: {
                color: "white",
              },
              overlaying: "y",
              side: "right",
              gridcolor: "rgba(128, 128, 128, 0.3)",
              nticks: 10,
            },
            showlegend: true,
            legend: { orientation: "h", x: 0, xanchor: "left", y: 1.1 },
            plot_bgcolor: "transparent",
            paper_bgcolor: "transparent",
            font: {
              color: "#ffffff",
            },
            autosize: true,
            margin: { l: 60, r: 80, t: 50, b: 70 },
            displayModeBar: true, // Always show the modebar
            modebar: {
              orientation: "v",
              x: 0.95, // Moved inside the plot area
              xanchor: "left",
              y: 1,
              yanchor: "top",
              bgcolor: "rgba(0, 0, 0, 0.3)",
            },
          }}
          style={{ width: "100%", height: "450px" }}
          useResizeHandler={true}
          config={{
            scrollZoom: true,
            displaylogo: false,
            responsive: true,
            displayModeBar: true, // Always show the modebar
          }}
        />
      )}
    </div>
  );
};

export default CumulativeProductionChart;
