import { useEffect,useState } from 'react'
import { categorizedOperators, createOperatorSchedules } from '../../util/assembleData'
import Pagenation from '../../util/pagenation';
import '../../styles/activitySubmitted.css'

const OperatorSummary = ({ data }) => {
  const [dataSet, setDataSet] = useState([])
  const [pagenation, setPagenation] = useState({
    hourly_ops:{start: 0, end: 10 },
    daily_ops:{start: 0, end: 10 },
    oil_haulers:{start: 0, end: 10 },
    water_haulers:{start: 0, end: 10 }
  });
  const [pageby, setPageby] = useState({
    hourly_ops:10,
    daily_ops:10,
    oil_haulers:10,
    water_haulers:10
  });
  const [currentPage, setCurrentPage] = useState({
    hourly_ops:1,
    daily_ops:1,
    oil_haulers:1,
    water_haulers:1
  });
  
  useEffect(() => {
    if (data) {
      
      const mappedOperators = createOperatorSchedules(data?.operations)
      const opertorsByTypes = categorizedOperators(mappedOperators)
      setDataSet(opertorsByTypes)
    } 
  },[data])
  if (!data) return (<div className="flex justify-center p-10 text-white">Loading Data...</div>)  
 
  
  const renderOperatorTable = ({ tableType }) => {
    return (
      <div>
        <div>
          <div className="w-full flex flex-row justify-evenly tb-cells heads">
            <div>Operator</div>
            <div>Count of frames worked</div>
            <div>Total hours worked</div>
            <div>Total km driven</div>
            <div>total hours driving</div>
            <div> total tasks performed</div>
          </div>
        { dataSet[tableType]?.slice(pagenation[tableType].start, pagenation[tableType].end).map((operators) => {
          const { operator_detail, operator_id, schedules } = operators
          const workHr = (operator_detail.afternoon_credits + operator_detail.hours_worked_in_shift).toFixed(2)
          const frames = schedules?.reduce((acc, cur) => {
            if (!acc.find((el) => el.frame === cur.frame)) {
              acc.push(cur)
            }
            return acc
          }, []) || []          
          return (
            <div className="w-full flex flex-row justify-evenly tb-cells text-white body border-b border-secondary">
              <div>{operator_id}</div>
              <div>{ frames.length}</div>
              <div>{workHr} hrs</div>
              <div>{operator_detail?.travelled_distance.toFixed(2) || 0} km</div>
              <div>{operator_detail?.travelled_time.toFixed(2) || 0} min</div>
              <div>{ schedules.length || 0} </div>
            </div>
            )
        })}
        </div>
        {dataSet[tableType].length > 10 && (
          <Pagenation
            pagenation={pagenation[tableType]}
            setPagenation={({ start, end }) => setPagenation({ ...pagenation, [tableType]: { start, end } })}
            setCurrentPage={(page) => setCurrentPage({ ...currentPage, [tableType]: page })}
            pageby={pageby[tableType]}
            currentPage={currentPage[tableType]}
            totalPages={Math.ceil(dataSet[tableType].length / pageby[tableType] || 10)}
            length={dataSet[tableType].length}
          />
        )}
    </div>)  
  }

  const renderHaulerTable = ({ tableType }) => {
    return (
      <div>
        <div>
          <div className="w-full flex flex-row justify-evenly tb-cells heads">
            <div>Operator</div>
            <div>Count of frames worked</div>
            <div>Total hauls</div>
            <div>Total volume hauled</div>            
          </div>
        { dataSet[tableType]?.slice(pagenation[tableType].start, pagenation[tableType].end).map((operators) => {
          const { operator_detail, operator_id, schedules } = operators          
          const frames = schedules?.reduce((acc, cur) => {
            if (!acc.find((el) => el.frame === cur.frame)) {
              acc.push(cur)
            }
            return acc
          }, []) || []          
          return (
            <div className="w-full flex flex-row justify-evenly tb-cells text-white body border-b border-secondary">
              <div>{operator_id}</div>
              <div>{ frames.length}</div>
              <div>{operator_detail?.capacity.toFixed(2) || 0}</div>
              <div>{operator_detail?.volume.toFixed(2) || 0}</div>              
            </div>
            )
        })}
        </div>
        {dataSet[tableType].length > 10 && (
          <Pagenation
            pagenation={pagenation[tableType]}
            setPagenation={({ start, end }) => setPagenation({ ...pagenation, [tableType]: { start, end } })}
            setCurrentPage={(page) => setCurrentPage({ ...currentPage, [tableType]: page })}
            pageby={pageby[tableType]}
            currentPage={currentPage[tableType]}
            totalPages={Math.ceil(dataSet[tableType].length / pageby[tableType] || 10)}
            length={dataSet[tableType].length}
          />
        )}
    </div>)  
  }
  if(!dataSet) return <div className="flex justify-center p-10 text-white">Loading Data...</div>
  return (
    <div className="w-full container operatorSummary">
      <h2>Operator Performance Summary</h2>
      {dataSet['hourly_ops'] && dataSet['hourly_ops'].length > 0 && (
        <div>
          <h3>Operators (Hourly)</h3>
          {renderOperatorTable({ tableType: 'hourly_ops' })}
        </div>
      )}
      {dataSet['daily_ops'] && dataSet['daily_ops'].length > 0 && (
        <div>
          <h3>Operators (Daily)</h3>
          {renderOperatorTable({ tableType: 'daily_ops' })}
        </div>
      )}
      {dataSet['oil_haulers'] && dataSet['oil_haulers'].length > 0 && (
        <div>
          <h3>Oil Haulers</h3>
          {renderHaulerTable({ tableType: 'oil_haulers' }) }
        </div>
      )}
      {dataSet['water_haulers'] && dataSet['water_haulers'].length > 0 && (
        <div>
          <h3>Water Haulers</h3>
          {renderHaulerTable({ tableType: 'water_haulers' }) }
        </div>
      )}

    </div>


  )
}

export default OperatorSummary