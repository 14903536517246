import MetricCard from "./MetricCard";
const MetrixDisplaySection = ({ data, header }) => {

  return (
    <div>
      <div>
        <h1 className="text-md font-500 text-primary">{header}</h1>
      </div>
      <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-2 mt-2'>
        {data.map((metric, index) => (
          <MetricCard
            dataKey={`metric-${metric.title}-${index}`}
            title={metric.title}
            value={metric.value}
            unit={metric.unit}
            icon={metric.icon}
            prefix={metric.prefix}
          />          
        ))}
      </div>
    </div>
  )
}

export default MetrixDisplaySection