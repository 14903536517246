import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

const CumulativeProductionChart = ({ productionData }) => {
  const [groupedData, setGroupedData] = useState({});
  const [chartData, setChartData] = useState([]);
  const [selectedWell, setSelectedWell] = useState(null);
  const [wellOptions, setWellOptions] = useState([]);

  // Metrics State
  const [totalProducedOil, setTotalProducedOil] = useState(0);
  const [totalProducedWater, setTotalProducedWater] = useState(0);
  const [totalProducedGas, setTotalProducedGas] = useState(0);

  useEffect(() => {
    if (!productionData || !Array.isArray(productionData.data)) {

      return;
    }

    const newGroupedData = {};
    const wellSet = new Set();
    let oilSum = 0,
      waterSum = 0,
      gasSum = 0;

    productionData.data.forEach((entry) => {
      const {
        metadata: { pad_id, well_id },
        values,
      } = entry;
      const wellKey = `${pad_id}-${well_id}`;
      wellSet.add(wellKey);

      values.forEach((value, index) => {
        const tick = index;
        const producedOil = value[7];
        const producedWater = value[8];
        const producedGas = value[6];

        oilSum += producedOil;
        waterSum += producedWater;
        gasSum += producedGas;

        if (!newGroupedData[wellKey]) {
          newGroupedData[wellKey] = {};
        }

        if (!newGroupedData[wellKey][tick]) {
          newGroupedData[wellKey][tick] = {
            produced_oil: producedOil,
            produced_water: producedWater,
            produced_gas: producedGas,
          };
        } else {
          newGroupedData[wellKey][tick].produced_oil += producedOil;
          newGroupedData[wellKey][tick].produced_water += producedWater;
          newGroupedData[wellKey][tick].produced_gas += producedGas;
        }
      });
    });

    setGroupedData(newGroupedData);
    setWellOptions(Array.from(wellSet));
    setTotalProducedOil(oilSum);
    setTotalProducedWater(waterSum);
    setTotalProducedGas(gasSum);
  }, [productionData]);

  useEffect(() => {
    if (Object.keys(groupedData).length > 0) {
      const firstWellKey = Object.keys(groupedData)[0];
      const ticks = Object.keys(groupedData[firstWellKey] || {});
      updateChartData(ticks, groupedData);
    }
  }, [selectedWell, groupedData]);

  const updateChartData = (ticks, data) => {
    const seriesData = [
      {
        x: ticks,
        y: selectedWell
          ? ticks.map((tick) => data[selectedWell]?.[tick]?.produced_oil || 0)
          : aggregateAcrossWells(ticks, data, "produced_oil"),
        name: "Oil",
        type: "bar",
        yaxis: "y1",
        marker: { color: "#FF5733" }, // Distinct color for oil
      },
      {
        x: ticks,
        y: selectedWell
          ? ticks.map((tick) => data[selectedWell]?.[tick]?.produced_water || 0)
          : aggregateAcrossWells(ticks, data, "produced_water"),
        name: "Water",
        type: "bar",
        yaxis: "y1",
        marker: { color: "#3357FF" }, // Distinct color for water
      },
      {
        x: ticks,
        y: selectedWell
          ? ticks.map((tick) => data[selectedWell]?.[tick]?.produced_gas || 0)
          : aggregateAcrossWells(ticks, data, "produced_gas"),
        name: "Gas",
        type: "scatter",
        mode: "lines",
        yaxis: "y2",
        line: { color: "#33FF57" }, // Distinct color for gas
      },
    ];

    setChartData(seriesData);
  };

  const aggregateAcrossWells = (ticks, data, productType) => {
    return ticks.map((tick) => {
      return Object.keys(data).reduce((acc, wellKey) => {
        return acc + (data[wellKey]?.[tick]?.[productType] || 0);
      }, 0);
    });
  };

  const handleWellChange = (event) => {
    setSelectedWell(event.target.value);
  };

  return (
    <div className="rounded-sm shadow-sm p-2 relative">
      <div className="mb-4 flex items-center justify-end">
        <select
          id="wellSelect"
          className="mt-1 block w-1/3 pl-3 py-2 bg-base-200 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={selectedWell || ""}
          onChange={handleWellChange}
        >
          <option value="">All Wells</option>
          {wellOptions.map((well) => (
            <option key={well} value={well}>
              {well}
            </option>
          ))}
        </select>
      </div>
      {chartData.length > 0 && (
        <Plot
          data={chartData}
          layout={{
            title: "Well Production by Frame",
            xaxis: {
              title: "Frame",
              tickfont: {
                color: "white",
              },
              gridcolor: "rgba(128, 128, 128, 0.3)",
              nticks: 20,
            },
            yaxis: {
              title: "Volume (bbl)",
              tickfont: {
                color: "white",
              },
              gridcolor: "rgba(128, 128, 128, 0.3)",
              nticks: 10,
            },
            yaxis2: {
              title: "Volume (mcf)",
              tickfont: {
                color: "white",
              },
              overlaying: "y",
              side: "right",
              gridcolor: "rgba(128, 128, 128, 0.3)",
              nticks: 10,
            },
            font: {
              color: "white",
            },
            plot_bgcolor: "transparent",
            paper_bgcolor: "transparent",
            showlegend: true,
            legend: { orientation: "h", x: 0, xanchor: "left", y: 1.1 },
            autosize: true,
            margin: { l: 60, r: 80, t: 50, b: 70 },
            displayModeBar: true, // Always show the modebar
            modebar: {
              orientation: "v",
              x: 0.95, // Moved inside the plot area
              xanchor: "left",
              y: 1,
              yanchor: "top",
              bgcolor: "rgba(0, 0, 0, 0.3)",
            },
          }}
          style={{ width: "100%", height: "450px" }}
          useResizeHandler={true}
          config={{
            scrollZoom: true,
            displaylogo: false,
            responsive: true,
            displayModeBar: true, // Always show the modebar
          }}
        />
      )}
    </div>
  );
};

export default CumulativeProductionChart;
