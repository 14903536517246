import React from "react";

const SimulationMetricsDisplay = ({ frameData }) => {
  const calculateMetrics = (data) => {
    if (!data || !data.pads) {
      return [
        { title: "Total Pads", value: "N/A", icon: "🏗️" },
        { title: "Gas Pipelines", value: "N/A", icon: "🔥" },
        { title: "Oil Pipelines", value: "N/A", icon: "🛢️" },
        { title: "Total Wells", value: "N/A", icon: "🏭" },
      ];
    }

    const { pads } = data;
    const totalPads = pads.length;
    const gasPipelinesCount = Object.keys(data?.gas_pipelines)?.length || 0;

    const oilPipelinesCount =  Object.keys(data?.oil_pipelines)?.length || 0;

    // for each pad, count .wells
    const totalWells = pads.reduce(
      (acc, pad) => acc + (pad?.wells?.length || 0),
      0
    );

    return [
      { title: "Total Pads", value: totalPads, icon: "🏗️" },
      { title: "Gas Pipelines", value: gasPipelinesCount, icon: "🔥" },
      { title: "Oil Pipelines", value: oilPipelinesCount, icon: "🛢️" },
      { title: "Total Wells", value: totalWells, icon: "🏭" },
    ];
  };

  const metrics = calculateMetrics(frameData);

  const MetricCard = ({ title, value, icon }) => (
    <div className="stats shadow bg-neutral  text-base-content   rounded-sm">
      <div className="stat">
        <div className="stat-figure text-info">
          <span className="text-lg">{icon}</span>
        </div>
        <div className="stat-title text-sm">{title}</div>
        <div className="stat-value text-sm badge">{value}</div>
      </div>
    </div>
  );

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 mt-2">
      {metrics.map((metric, index) => (        
        <MetricCard
          dataKey={`metric-${metric.title}-${index}`}
          title={metric.title}
          value={metric.value}
          icon={metric.icon}
        />        
      ))}
    </div>
  );
};

export default SimulationMetricsDisplay;
