import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from "react";
import queryString from "query-string";

import { history } from "./router";

// Create a `useAuth` hook and `AuthProvider` that enables
// any component to subscribe to auth and re-render when it changes.
const authContext = createContext();
export const useAuth = () => useContext(authContext);
// This should wrap the app in `src/pages/_app.js`
export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  // Memoize the auth value to prevent unnecessary re-renders
  const memoizedAuth = React.useMemo(() => auth, [auth]);

  return (
    <authContext.Provider value={memoizedAuth}>{children}</authContext.Provider>
  );
}

// Hook that creates the `auth` object and handles state
// This is called from `AuthProvider` above (extracted out for readability)
// Hook that creates the `auth` object and handles state
const baseURL = process.env.REACT_APP_API_URL; // "http://49.12.241.214:9500"; //"https://mavhack.com";
function useAuthProvider() {
  // Store auth user in state
  const [user, setUser] = useState(null);

  // Check if the user is already authenticated on mount
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch(baseURL + "/api/check-auth", {
          credentials: "include", // Include cookies in the request
        });

        if (response.ok) {
          const user = await response.json();
          setUser(user);
        }
      } catch (error) {
        console.error("Error checking auth:", error);
      }
    };

    checkAuth();
  }, []);

  // Handle response from signin
  const handleAuth = async (response) => {
    if (!response.ok) {
      throw new Error(
        `Failed to sign in: ${response.status} ${response.statusText}`
      );
    }

    const user = await response.json();

    // Update user in state
    setUser(user);
    return user;
  };

  const signin = async (email, password) => {
    try {
      const response = await fetch(baseURL + "/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
        credentials: "include", // Include cookies in the request
      });

      return handleAuth(response);
    } catch (error) {
      console.error("Error signing in:", error);
      // Handle error, e.g., display an error message
    }
  };

  const signout = async () => {
    try {
      await fetch(
        `${baseURL}/api/logout`,
        {
          credentials: "include", // Include cookies in the request
        }
      );
      setUser(null);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return {
    user,
    signin,
    signout,
  };
}

// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
  return function RequireAuthHOC(props) {
    const auth = useAuth();

    // Use useMemo to memoize the check for `auth.user`
    const shouldRedirect = useMemo(() => !auth.user, [auth.user]);

    useEffect(() => {
      if (shouldRedirect) {
        history.replace("/auth/signin");
      }
    }, [shouldRedirect]);

    // Render component if `auth.user` is truthy, or render null if falsy
    return auth.user ? <Component {...props} /> : null;
  };
};

const getFromQueryString = (key) => {
  return queryString.parse(window.location.search)[key];
};
