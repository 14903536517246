import React from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";

function AuthPage(props) {
  const router = useRouter();
  const auth = useAuth();

  return (
    <>
      <Meta title="Auth" />
      {auth.user ? (
        router.push("/dashboard")
      ) : (
        <AuthSection
          size="md"
          bgColor="bg-white-disabled"
          bgImage=""
          bgImageOpacity={1}
          textColor=""
          type={router.query.type}
          providers={["google", "facebook", "twitter"]}
          afterAuthPath={router.query.next || "/dashboard"}
        />
      )}
    </>
  );
}

export default AuthPage;
