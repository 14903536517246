const initialValue = {
  "Calculated Cashflow Fields": {
    "Equivalent Volumes (sold/frame)": {
      "BOE(gas)": 0,
      "BOE(ngl)": 0,
      "BOE(oil)": 0,
      "TOTAL BOEPD": 0
    },
    "Expenses": {
      "Total Controllable Expenses ($)": 0,
      "Total LOE Expenses Pre-Tax ($)": 0,
      "Total Non-Controllable Expenses ($)": 0
    },
    "Metrics": {
      "CONTROLLABLE / BOE": 0,
      "LOE PRETAX / BOE": 0,
      "OPERATING CASH MARGIN / BOE": 0,
      "OPERATING EXP / BOE": 0,
      "REVENUE / BOE": 0
    },
    "Net Calculations": {
      "Operating Cash Margin ($)": 0,
      "Price Equivalent ($/BOE)": 0,
      "Total LOE Expenses Inc. Taxes ($)": 0
    },
    "Revenue": {
      "GAS SALES ($)": 0,
      "GROSS REVENUE ($)": 0,
      "NGL SALES ($)": 0,
      "OIL SALES ($)": 0
    },
    "Taxes": {
      "AD VALOREM TAX": 0,
      "SEVERANCE TAX": 0,
      "TOTAL PRODUCTION & PROPERTY TAXES": 0,
      "Total Taxes ($)": 0
    }
  },
  "Direct LOE": {
    "CHEMICALS ($/frame)": 0,
    "COMMUNICATIONS/MEASUREMENTS ($/frame)": 0,
    "COMPANY LABOR ($/frame)": 0,
    "CONTRACT LABOR / PROF SERV ($/frame)": 0,
    "EH&S / REGULATORY ($/frame)": 0,
    "FUEL & UTILITIES ($/frame)": 0,
    "PRODUCTION SUPPLIES / MISC. ($/frame)": 0,
    "RENTALS ($/frame)": 0,
    "REPAIRS AND MAINTENANCE ($/frame)": 0,
    "ROAD REPAIR & MAINTENANCE ($/frame)": 0,
    "VEHICLES ($/frame)": 0,
    "WATER HAULING & DISPOSAL ($/day)": 0,
    "WATER HAULING & DISPOSAL ($/frame)": 0,
    "WELL SERVICES ($/frame)": 0
  },
  "Non-Controllable Expenses": {
    "GAS PROCESSING FEES": 0,
    "NGL PROCESSING FEES": 0,
    "OIL PROCESSING FEES": 0
  },
  "Production Volume": {
    "GROSS OIL VOLUME PRODUCED (bbl/frame)": 0,
    "GROSS GAS VOLUME PRODUCED (mcf/frame)": 0,
    "GROSS WATER VOLUME PRODUCED (bbl/frame)": 0,
    "NET GAS VOLUME PIPED (mcf/frame)": 0,
    "OIL VOLUME SOLD (bbl/frame)": 0,
    "GAS VOLUME SOLD (mcf/frame)": 0,
    "NGL VOLUME SOLD (gallons/frame)": 0,
    "WATER VOLUME DISPOSED (bbl/frame)": 0,
    "GROSS GAS FLARED (mcf/frame)": 0,
    "OIL VOLUME SPILLED (bbl/frame)": 0,
    "WATER VOLUME SPILLED (bbl/frame)": 0, 
  },
  "Spot Prices": {
    "GAS PRICE ($/MCF)": 0,
    "NGL PRICE ($/GAL)": 0,
    "OIL PRICE ($/BBL)": 0
  },
  "datetime": 1
}
function addValues(acc, val) {
  for (const key in val) {
    if (typeof val[key] === 'object' && val[key] !== null) {
      if (!acc.hasOwnProperty(key)) {
        acc[key] = {};
      }
      addValues(acc[key], val[key]);
    } else {
      if (!acc.hasOwnProperty(key)) {
        acc[key] = 0;
      }
      acc[key] += val[key];
    }
  }
}
const getSpotPriceAverage = (result, cashflow, key) => {
  const filteredData = cashflow.filter((item) => item['Spot Prices']?.hasOwnProperty(key));
  return result / (filteredData.length/2);
}
const assembleCashFlowData = (data) => {
  let result = data.cashflow_statements.reduce((acc, val) => {
    if (Object.keys(val).length === 0) return acc;
  
    addValues(acc["Calculated Cashflow Fields"], val["Calculated Cashflow Fields"]);
    addValues(acc["Direct LOE"], val["Direct LOE"]);
    addValues(acc["Non-Controllable Expenses"], val["Non-Controllable Expenses"]);
    addValues(acc["Production Volume"], val["Production Volume"]);
    addValues(acc["Spot Prices"], val["Spot Prices"]);
    return acc;
  }, initialValue);
  
  return {
    data, ...{
      cashflow_statement: {
        ...result,
        "Spot Prices": {
          'GAS PRICE ($/MCF)': getSpotPriceAverage(result['Spot Prices']['GAS PRICE ($/MCF)'], data.cashflow_statements, 'GAS PRICE ($/MCF)'),
          'NGL PRICE ($/GAL)': getSpotPriceAverage(result['Spot Prices']['NGL PRICE ($/GAL)'], data.cashflow_statements, 'NGL PRICE ($/GAL)'),
          'OIL PRICE ($/BBL)': getSpotPriceAverage(result['Spot Prices']['OIL PRICE ($/BBL)'], data.cashflow_statements, 'OIL PRICE ($/BBL)')
        }
      }
    }
  };
}
const getProductVolume = (data, key, target) => {
  let result = []
  data.forEach((item, frame) => {
    result.push({
      volume: item[key] && item[key][target]? item[key][target] : 0,
      frameNo: frame,
    })
  })
  
  return result
}

export const getProductVolumeSeries = ({ data, labels,targets, config }) => {
  let result = []
  data && data?.map((d, i) => {
    if (d) {
      result.push(
        {
          x: d.map(item => item.frameNo),
          y: d.map(item => item.volume),
          name: labels[i].header,
          type: config[i].type,
          marker: {
            color: config[i].color
          },
        }
      )
    }
  })
  return result
  
}

export const getProductHandlingChartLayout = ({ commonLabels}) => {
  return {
    title: {
      text: commonLabels.header,
      font: {
        family: "Arial, sans-serif",
        size: 18,
        color: "#ffffff",
      },
    },
    xaxis: {
      title: commonLabels.x,
      tickfont: {
        color: "white",
      },
      gridcolor: "rgba(128, 128, 128, 0.3)",
      showgrid: false, // Remove grid lines
      zeroline: false, // Remove zero line
      showline: true, // Add axis line
      linecolor: "rgba(255, 255, 255, 0.2)", // Light axis line color
    },
    yaxis: {
      title: commonLabels.y,
      tickfont: {
        color: "white",
      },
      gridcolor: "rgba(128, 128, 128, 0.3)",
      nticks: 10,
      showgrid: true, // Add grid lines
      zeroline: false, // Remove zero line
      showline: true, // Add axis line
      linecolor: "rgba(255, 255, 255, 0.2)", // Light axis line color
      automargin: true, // Auto-scale the y-axis
    },
    showlegend: true,
    legend: {
      orientation: "h",
      x: 0,
      xanchor: "left",
      y: 1.1,
      font: {
        color: "#ffffff",
      },
    },
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    font: {
      color: "#ffffff",
    },
    autosize: true,
    margin: { l: 60, r: 20, t: 50, b: 70 }, // Reduced right margin to minimize space between chart and controls
    modebar: {
      orientation: "v",
      x: 1,
      xanchor: "right",
      y: 1,
      yanchor: "top",
      bgcolor: "rgba(0, 0, 0, 0.3)",
    },
  }
}
export const getProductHandlingData = (data) => {
  const cashflowStatements = data.cashflow_statements;
  return {
    oil: {
      spilled:getProductVolume(cashflowStatements, 'Production Volume', 'OIL VOLUME SPILLED (bbl/frame)'),
      hauled: getProductVolume(cashflowStatements, 'Production Volume', 'OIL VOLUME SOLD (bbl/frame)')
    },
    water: {
      spilled: getProductVolume(cashflowStatements, 'Production Volume', 'WATER VOLUME SPILLED (bbl/frame)'),
      disposed: getProductVolume(cashflowStatements, 'Production Volume', 'WATER VOLUME DISPOSED (bbl/frame)')
    },
    gas: {
      flared: getProductVolume(cashflowStatements, 'Production Volume', 'GROSS GAS FLARED (mcf/frame)'),
      piped: getProductVolume(cashflowStatements, 'Production Volume', 'GAS VOLUME SOLD (mcf/frame)')
    }
  }
}

export const scheduleToOjectArray = (data) => {
  const result = []
  data.forEach((item, index) => {
    const hasOperator = result.filter((schedule) => (schedule.pad_id === item[1]))
    if (hasOperator.length > 0) {
      hasOperator[0].schedules.push({operator_id: item[0], task: item[2]})
    }
    else {
      result.push({
        pad_id: item[1],
        schedules: [{operator_id: item[0], task: item[2]}]
      })
    }
  })
  return result
}


export const mapScheduleToOperators = (operator_schedules, operators) => {
  let scheduleToOperators = [];
  operator_schedules.forEach((schedule) => {
    const asset = scheduleToOperators.find((os) => os.asset_id === schedule.asset_id);
    if (asset) {
      asset.operators.push(...operators.filter((operator) => operator.id === schedule.operator_id));
    }
    else {
      scheduleToOperators.push({ ...schedule, operators: operators.filter((operator) => operator.id === schedule.operator_id) });
    }
    
  })
  return scheduleToOperators
}

export const mapOperatorToSchedule = (operator_schedules, operators) => {
  const operatorToSchedules = operators?.map((operator) => {
    return {
      ...operator,
      schedules: operator_schedules?.filter((schedule) => schedule.operator_id === operator.id)
    }
  });
  return operatorToSchedules
}

export const mapScheduleToOperatorsBasedOnPad = (operator_schedules, operators) => {
  let scheduleToOperators = [];
  operator_schedules?.forEach((schedule) => {
    const asset = scheduleToOperators?.find((sch) => sch.asset_id === schedule.asset_id); 
    if (!asset) {
      
      scheduleToOperators.push({
        asset_id: schedule.asset_id,
        schedules: operator_schedules
          ?.filter((os) => os.asset_id === schedule.asset_id)
          .map((s) => {
            return {
              ...s,
              operator_detail: operators.find((op) => op.id === s.operator_id)
            }
          })
      });
    }
    
  })
  return scheduleToOperators
}

export const createOperatorSchedules = (operations) => {
  if (!operations) return []
  let result = [];
  operations?.forEach((operation) => { 
    const { datetime, operator_schedules, operators } = operation
    operator_schedules?.forEach((schedule) => {
      const op = result.find((op) => op.operator_id === schedule.operator_id)
      if (op) {
        op.schedules.push({ ...schedule, frame: datetime })
      }
      else {
        const operator_detail = operators.find((op) => op.id === schedule.operator_id)
        result.push({ operator_detail, operator_id: schedule.operator_id, schedules: [{ ...schedule, frame: datetime }] })
      }

    })
    return result
  })
  return result
}

export const categorizedOperators = (mappedOperators) => {
  const operators = {
    hourly_ops: [],
    daily_ops: [],
    oil_haulers: [],
    water_haulers: []
  }
  const hourly_roles = ["lease_operator", "roustabout", "maintenance_tech", "ie_tech", "compressor_tech", "electrician", "chemical_tech"]
  const daily_roles = ["swab_rig", "workover_rig", "hot_oil_truck"]
  mappedOperators.forEach((operator) => {
    if (operator.operator_id.includes('OP')) {
      if (hourly_roles.includes(operator?.operator_detail?.type)) {
        operators.hourly_ops.push(operator) 
      }
      if(daily_roles.includes(operator?.operator_detail?.type)) {
        operators.daily_ops.push(operator)
      }
    }
    else if (operator.operator_id.includes('OilHauler')) {
      operators.oil_haulers.push(operator)
    }
    else if (operator.operator_id.includes('WaterHauler')) {
      operators.water_haulers.push(operator)
    }
  })
  return operators
}



export default  assembleCashFlowData